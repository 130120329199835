import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import apiService from '../services/api';
import { FCMTOKEN } from '../constant/_const';

function log(params) {
  if (typeof params === 'object') {
    console.log(
      '%c' + JSON.stringify(params, null, 2),
      'color: #3498db; font-size: 16px; font-weight: bold; background: #ecf0f1; padding: 2px 4px; border-radius: 4px;'
    );
  } else {
    console.log(
      '%c' + params,
      'color: #3498db; font-size: 16px; font-weight: bold; background: #ecf0f1; padding: 2px 4px; border-radius: 4px;'
    );
  }
}

function showAlert(title, message, icon) {
  Swal.fire({
    title: title,
    text: message,
    icon: icon,
  });
}

function toastAlert(title, message, position = "top-start", timer = 5000,) {
  const Toast = Swal.mixin({
    toast: true,
    position: position,
    showConfirmButton: false,
    timer: timer,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });

  Toast.fire({
    icon: title,
    title: message
  });
}

function ForEach(array, cle) {
  array.forEach((element) => {
    return (element[cle]);
  });
}

function manageLocalStorage(action, keys, value = null) {
  const isSingleKey = !Array.isArray(keys);
  if (isSingleKey) {
    keys = [keys];
  }

  const results = {};

  keys.forEach(key => {
    switch (action) {
      case 'add':
        localStorage.setItem(key, JSON.stringify(value));
        break;

      case 'update':
        if (localStorage.getItem(key) !== null) {
          localStorage.setItem(key, JSON.stringify(value));
          console.log(`Mis à jour: ${key}`);
        } else {
          console.log(`Impossible de mettre à jour, clé non trouvée: ${key}`);
        }
        break;

      case 'remove':
        if (localStorage.getItem(key) !== null) {
          localStorage.removeItem(key);
          console.log(`Supprimé: ${key}`);
        } else {
          console.log(`Impossible de supprimer, clé non trouvée: ${key}`);
        }
        break;

      case 'get':
        const storedValue = localStorage.getItem(key);
        results[key] = storedValue ? JSON.parse(storedValue) : null;
        break;

      default:
        console.log("Action non reconnue. Utilisez 'add', 'update', 'remove' ou 'get'.");
    }
  });

  /* Retourner le résultat pour 'get' */
  if (action === 'get') {
    return isSingleKey ? results[keys[0]] : results; /* Si une seule clé est passée, retourner directement sa valeur */
  }
}

// Fonction générique pour filtrer un tableau d'objets
const filterObjects = (array, key, value) => {
  return array.filter(item => item[key] === value);
};

// Fonction générique pour trouver un objet dans un tableau
const findObject = (array, key, value) => {
  return array.find(item => item[key] === value);
};

// Fonction générique pour mettre à jour un champ d'un objet dans un tableau
const updateField = (array, key, field, newValue) => {
  return array.map(item => (item[key] === field ? { ...item, [field]: newValue } : item));
};

// Fonction générique pour supprimer un objet dans un tableau
const removeObject = (array, key, value) => {
  return array.filter(item => item[key] !== value);
};

// Fonction générique pour filtrer plusieurs critères
const filterByMultipleCriteria = (array, criteria) => {
  return array.filter(item => {
    return Object.keys(criteria).every(key => item[key] === criteria[key]);
  });
};

function notifyUser(title, message) {
  if ('Notification' in window && Notification.permission === 'granted') {
    const notification = new Notification(title, {
      body: message,
      icon: 'logo192.png',
    });

    notification.onclick = function () {
      window.focus();
    };
  } else {
    console.error('Notification permission denied or not granted.');
  }
}

function detectDevice() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Vérifier si l'appareil est un mobile
  if (/android/i.test(userAgent)) {
    return "mobile";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "mobile";
  }

  // Vérifier si l'appareil est une tablette
  if (/iPad|Tablet/.test(userAgent)) {
    return "tablet";
  }

  // Si l'appareil n'est ni un mobile ni une tablette, on suppose que c'est un PC
  return "desktop";
}

const notify = (message, type = 'info', position = 'top-left') => {
  const config = {
    position: position,
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    icon: true,
    theme: "light",
  };

  switch (type) {
    case 'success':
      toast.success(message, config);
      break;
    case 'error':
      toast.error(message, config);
      break;
    case 'warn':
      toast.warn(message, config);
      break;
    case 'info':
    default:
      toast.info(message, config);
      break;
  }
};

function sendNotification(tokenKey, message, title) {
  const token = localStorage.getItem(tokenKey);
  if (token) {
    apiService.post(FCMTOKEN, { token: token, message: message, title: title })
      .catch(error => {
        console.error('Erreur lors de l\'envoi de la notification FCM:', error);
      });
  } else {
    console.warn('Token FCM non disponible dans le localStorage.');
  }
}

export {
  log,
  showAlert,
  ForEach,
  toastAlert,
  manageLocalStorage,
  filterObjects,
  findObject,
  updateField,
  removeObject,
  filterByMultipleCriteria,
  notifyUser,
  detectDevice,
  notify,
  sendNotification
};
