import React, { useState } from 'react';
import { SEARCH_QUERY } from '../constant/_const';
import apiService from '../services/api';
import { manageLocalStorage } from '../func/_func';
import { useNavigate } from 'react-router-dom';
import Loader from '../common/Loader';
import '../assets/css/_recherche.css';

const Recherche = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const timeout = 1000;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const apiResponse = await apiService.post(SEARCH_QUERY, { searchQuery });
      if (apiResponse) {
        manageLocalStorage('add', 'searchResults', apiResponse);
        setTimeout(() => {
          navigate(`/searchquery?resultat=${searchQuery}`);
          setLoading(false);
        }, timeout);
      }
    } catch (error) {
      console.error("Erreur lors de la recherche :", error);
      setLoading(false);
    }
  };

  return (
    <><div className="recherche-container px-4">
      <form className="recherche-form" onSubmit={handleSubmit}>
        <input
          type="text"
          id="search_query"
          className="recherche-input"
          placeholder="Rechercher..."
          required
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }} />
        <button type="submit" className="recherche-button">
          <span className='-ml-2'>Rechercher</span>
        </button>
      </form>
      {loading && (
        <Loader />
      )}
    </div><br /></>
  );
};

export default Recherche;
