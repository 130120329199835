import React from "react";
import ReactDOM from "react-dom/client";
import "flowbite/dist/flowbite.css";
import "flowbite";
import "./index.css";
import "react-loading-skeleton/dist/skeleton.css";
import "animate.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CartProvider } from "./services/CartContext";
import initPostHog from "./services/posthog";
import { PostHogProvider } from "posthog-js/react";
const posthog = initPostHog();
initPostHog();
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <CartProvider>
      <PostHogProvider client={posthog}>
        <App />
      </PostHogProvider>
    </CartProvider>
  </React.StrictMode>
);
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/service-worker.js")
    .then((reg) => "")
    .catch((err) => "");
}
reportWebVitals();
