import React, { useEffect } from "react";
import { notifyUser } from "../../func/_func";

const Failled = () => {
  useEffect(() => {
    notifyUser('Paiement Échoué 😔', 'Le paiement a échoué. Veuillez vérifier vos informations.');
  }, []);
  return (
    <div className="flex h-screen items-center justify-center bg-gradient-to-r from-red-100 via-gray-100 to-red-100">
      <div className="max-w-md w-full bg-white shadow-xl rounded-lg p-8 text-center">
        <div className="flex justify-center mb-6">
          <svg
            className="w-16 h-16 text-red-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
        <h2 className="text-3xl font-bold text-gray-800 mb-4">
          Paiement Échoué 😔
        </h2>
        <p className="text-gray-600 mb-6 text-lg">
          Le paiement a échoué. Cela peut être dû à des fonds insuffisants ou un autre problème.
        </p>
        <p className="text-lg text-gray-800 font-semibold mb-4">
          Veuillez vérifier vos informations et réessayer.
        </p>
        <a
          href="/"
          className="inline-block bg-red-500 text-white px-6 py-3 rounded-full font-semibold hover:bg-red-600 transition duration-300 shadow-lg transform hover:scale-105"
        >
          Retour à la page d'accueil
        </a>
      </div>
    </div>
  );
};

export default Failled;