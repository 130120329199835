/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Loader from "../../common/Loader";
import Swal from "sweetalert2";
import apiService from "../../services/api";
import { CREER_PAIEMENT, ERROR_PAY, ERROR_RECUP, FCMTOKEN, PSR_URL, REGION, REGION_PRICE } from "../../constant/_const";
import { initFlowbite } from "flowbite";
import { detectDevice, log, manageLocalStorage, notify, notifyUser, sendNotification, showAlert, toastAlert } from "../../func/_func";
import omimg from '../../assets/images/orangemoneyyW14FYpiuoXBJrUlFIEw.png';
import waveimg from '../../assets/images/wavejmJd38CrLK5AIrCvRI3G1.jpeg';
import maxitlogo from '../../assets/images/maxit.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClipboardList, faShoppingBag } from '@fortawesome/free-solid-svg-icons';

export function PaymentUtils({ dakarDistrict, order, orderItem }) {
  const [loading, setLoading] = useState(false);
  const [fullName, setFullName] = useState("");
  const [authorizationCode, setauthorizationCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [region, setRegion] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [deliveryData, setDeliveryData] = useState([]);
  const [regionPrice, setRegionPrice] = useState([]);
  const [deliveryPaymentMethod, setDeliveryPaymentMethod] = useState("en_ligne");
  const [districtPrice, setDistrictPrice] = useState("");
  const [livraisonPrice, setLivraisonPrice] = useState("");
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paymentMethodOM, setPaymentMethodOM] = useState('');
  const [selectedpaymentModeOM, setSelectedPaymentModeOM] = useState('');
  const [deviceType, setDeviceType] = useState(null);

  useEffect(() => {
    initFlowbite();
    const device = detectDevice();
    setDeviceType(device);
  }, []);

  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const response = await apiService.get(REGION);
        setDeliveryData(response);
      } catch (error) {
        console.error(ERROR_RECUP);
      }
    };
    fetchRegions();
  }, []);

  useEffect(() => {
    const fetchRegionPrice = async () => {
      try {
        const response = await apiService.get(REGION_PRICE);
        setRegionPrice(response);
      } catch (error) {
        console.error(ERROR_RECUP);
      }
    };
    fetchRegionPrice();
  }, []);

  const ShowAlertModePaiement = () => {
    switch (deliveryPaymentMethod) {
      case 'en_ligne':
        toastAlert("success", "Vous avez choisi l'option payer à la livraison")
        break;
      case 'apres_livraison':
        toastAlert("success", "Vous avez choisi l'option payer en ligne")
        break;
      default:
        break;
    }
  }

  /* Événement lors du changement de région */
  const handleRegionChange = (e) => {
    const newRegion = e.target.value;
    setRegion(newRegion);
    setSelectedDistrict("");
    setDistrictPrice("");
    setDeliveryPaymentMethod("en_ligne");

    const regionData = regionPrice.find(r => r.regionns.name === newRegion);

    if (regionData) {
      setLivraisonPrice(regionData.price);
    } else {
      setLivraisonPrice("");
    }
  };

  /* Événement lors du changement de district */
  const handleDistrictChange = (e) => {
    const selected = e.target.value;
    setSelectedDistrict(selected);

    const districtData = dakarDistrict.find(district => district.district === selected);

    if (districtData) {
      setDistrictPrice(districtData.price);
      setLivraisonPrice(districtData.price);
    } else {
      setDistrictPrice("");
      setLivraisonPrice("");
    }
  };
  const paymentTotal = manageLocalStorage('get', 'totalPrice');

  const calculateTotal = () => {
    const livraisonCost = parseFloat(livraisonPrice) || 0;
    const totalPayment = parseFloat(paymentTotal) || 0;
    return totalPayment + livraisonCost;
  };

  /* desactiver button passer a la caisse */
  const isButtonDisabled = calculateTotal() <= 0;

  /* validation formulaire */
  const validateForm = () => {
    if (!fullName || !phoneNumber || !region || !authorizationCode) {
      Swal.fire("Erreur", "Tous les champs sont requis.", "error");
      return false;
    }

    /* Si la région est "Dakar", le district devient obligatoire */
    if (region === "Dakar" && !selectedDistrict) {
      Swal.fire("Erreur", "Veuillez sélectionner un district.", "error");
      return false;
    }

    const phoneRegex = /^[0-9]{9}$/;
    if (!phoneRegex.test(phoneNumber)) {
      Swal.fire(
        "Erreur",
        "Le numéro de téléphone doit comporter exactement 9 chiffres.",
        "error"
      );
      return false;
    }

    /* Validation du montant minimum */
    if (deliveryPaymentMethod === "en_ligne" && calculateTotal() <= 0) {
      Swal.fire(
        "Erreur",
        "Le montant total doit être supérieur à zéro pour un paiement en ligne.",
        "error"
      );
      return false;
    }

    if (deliveryPaymentMethod === "apres_livraison" && !livraisonPrice) {
      Swal.fire(
        "Erreur",
        "Les frais de livraison doivent être définis pour le paiement à la livraison.",
        "error"
      );
      return false;
    }
    if (paymentMethod !== "wave-senegal" && paymentMethod !== "new-orange-money-senegal") {
      Swal.fire(
        "Erreur",
        "Veuillez sélectionner une méthode de paiement.",
        "error"
      );
      return false;
    }

    if (paymentMethod === "new-orange-money-senegal") {
      if (paymentMethodOM !== "QR_CODE" && paymentMethodOM !== "CODE_OTP") {
        Swal.fire(
          "Erreur",
          "Veuillez sélectionner le mode de paiement Orange Money que vous souhaitez utiliser : Payer par code ou Payer par QR code.",
          "error"
        );
        return false;
      }
      else if (paymentMethodOM === "QR_CODE" && deviceType === 'mobile' && selectedpaymentModeOM !== "maxit" && selectedpaymentModeOM !== "om") {
        Swal.fire(
          "Erreur",
          "Veuillez choisir le mode d'application Orange à utiliser pour finaliser votre paiement : soit via l'application Max-it, soit via l'application Orange Money.",
          "error"
        );
        return false;
      }
    }
    return true;
  };

  /* Paiement processing */
  const ConfirmerPaiement = async () => {
    if (!validateForm()) {
      return;
    }

    Swal.fire({
      title: "Vous confirmez ?",
      text: "Êtes-vous sûr de vouloir confirmer le paiement ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Annuler",
      confirmButtonText: "Oui, confirmer !",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const totalPayment = deliveryPaymentMethod === 'apres_livraison'
          ? parseFloat(districtPrice || 0)
          : parseFloat(calculateTotal());

        const paymentData = {
          fullName,
          phoneNumber,
          region,
          total: totalPayment,
          order: order,
          orderItem: orderItem,
          selectedDistrict: selectedDistrict,
          paymentMethod,
          authorizationCode,
          livraisonPrice,
          deliveryPaymentMethod,
        };

        let timerInterval;
        Swal.fire({
          title: "Traitement en cours",
          html: "Redirection dans <b></b> secondes.",
          timer: 10000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            const b = Swal.getHtmlContainer().querySelector("b");
            timerInterval = setInterval(() => {
              const remainingTime = Swal.getTimerLeft() / 1000;
              b.textContent = Math.ceil(remainingTime);
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        });

        try {
          setLoading(true);
          const response = await apiService.post(CREER_PAIEMENT, paymentData);
          if (response.payment_data) {
            Swal.close();
            const token = response.payment_data.token;
            switch (paymentMethod) {
              case "wave-senegal":
                const responseApiWave = await apiService.post(PSR_URL + paymentMethod, {
                  wave_senegal_fullName: fullName,
                  wave_senegal_phone: phoneNumber,
                  wave_senegal_email: "exemple@gmail.com",
                  wave_senegal_payment_token: token,
                });

                if (responseApiWave) {
                  window.location.href = responseApiWave.url;
                } else {
                  sendNotification("fcmToken", 'Le paiement a échoué. Veuillez vérifier vos informations.', 'Paiement échoué');
                  Swal.fire("Erreur", responseApiWave.message, "error");
                }
                break;
              case "new-orange-money-senegal":
                if (paymentMethodOM === 'CODE_OTP') {
                  const responseApiOM = await apiService.post(PSR_URL + paymentMethod, {
                    customer_name: fullName,
                    customer_email: "exemple@exemple.com",
                    phone_number: phoneNumber,
                    authorization_code: authorizationCode,
                    invoice_token: token,
                    api_type: "OTPCODE"
                  });

                  if (responseApiOM) {
                    window.location.href = responseApiOM.return_url;
                  } else {
                    sendNotification("fcmToken", 'Le paiement a échoué. Veuillez vérifier vos informations.', 'Paiement échoué');
                    notify('Paiement Échoué 😔 Veuillez vérifier vos informations.', 'error');
                    Swal.fire("Erreur", responseApiOM.message, "error");
                  }
                } else if (paymentMethodOM === 'QR_CODE') {
                  try {
                    const responseApiOM = await apiService.post(PSR_URL + paymentMethod, {
                      customer_name: fullName,
                      customer_email: "exemple@exemple.com",
                      phone_number: phoneNumber,
                      authorization_code: authorizationCode,
                      invoice_token: token,
                      api_type: "QRCODE"
                    });

                    if (responseApiOM) {
                      const redirect_url = responseApiOM.other_url;
                      if (selectedpaymentModeOM === 'maxit') {
                        window.location.href = deviceType === 'mobile' || deviceType === 'tablet' ? redirect_url.maxit_url : responseApiOM.url;
                      } else if (selectedpaymentModeOM === 'om') {
                        window.location.href = deviceType === 'mobile' || deviceType === 'tablet' ? redirect_url.om_url : responseApiOM.url;
                      } else {
                        window.location.href = responseApiOM.url;
                      }
                    } else {
                      sendNotification("fcmToken", 'Le paiement a échoué. Veuillez vérifier vos informations.', 'Paiement échoué');
                      Swal.fire("Erreur", responseApiOM.message, "error");
                    }
                  } catch (error) {
                    sendNotification("fcmToken", 'Le paiement a échoué. Veuillez vérifier vos informations.', 'Paiement échoué');
                    console.error(error);
                  }
                }
                break;
              default:
                Swal.fire("Erreur", "Type de paiement inconnue", "error");
                notifyUser('Paiement Échoué 😔', 'Le paiement a échoué. Veuillez vérifier vos informations.');
                break;
            }
          }
          else if (response.fail_paiement) {
            Swal.close();
            sendNotification("fcmToken", 'Le paiement a échoué. Veuillez vérifier vos informations.', 'Paiement échoué');
            Swal.fire(
              "Erreur",
              "Une erreur est survenue lors du traitement du paiement, le total calculé est incorrect !",
              "error"
            );
          }
        } catch (error) {
          if (paymentMethodOM === 'CODE_OTP') {
            if (error.response.data) {
              sendNotification("fcmToken", 'Le paiement a échoué. Veuillez vérifier vos informations.', 'Paiement échoué');
              showAlert('Erreur', "Il semble que votre code temporaire ait expiré, qu'il ne soit pas valide, ou que le solde de votre compte Orange Money ne soit pas suffisant. Veuillez vérifier ces informations et réessayer.", 'error');
            }
          } else {
            sendNotification("fcmToken", 'Le paiement a échoué. Veuillez vérifier vos informations.', 'Paiement échoué');
            showAlert('Erreur', 'Une erreur est survenue lors du traitement de votre paiement. Veuillez vérifier vos informations et réessayer.', 'error');
            console.error("Erreur lors du traitement du paiement :", error);
          }
        }
        finally {
          setLoading(false);
        }
      }
    });
  };

  return (
    <>
      <button
        data-modal-target="paimentprocessing"
        data-modal-toggle="paimentprocessing"
        className={`px-4 py-2 rounded-md font-semibold text-white transition-colors duration-300 ${isButtonDisabled
          ? "bg-gray-400 cursor-not-allowed"
          : "bg-blue-600 hover:bg-blue-700"
          }`}
        type="button"
        id="btnToDesabled"
        disabled={isButtonDisabled}
      >
        Passer à la caisse
      </button>

      <div
        id="paimentprocessing"
        tabIndex={-1}
        className=" myPaymentUtils fixed top-0 left-0 right-0 z-50 hidden w-full p-4  overflow-x-hidden overflow-y-auto md:inset-0 h-full max-h-full"
      >
        {loading && (
          <div className="z-50 -mt-96"> <Loader /></div>
        )}
        <div className="relative w-full max-w-4xl max-h-full animate__animated animate__fadeInDown">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <form className="pb-4 space-y-2">
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-green-800 dark:text-white">
                  FINALISER LE PAIEMENT
                </h3>

                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="paimentprocessing"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>

              </div>
              <div className="flex flex-col items-center space-y-2">
                {/* Titre */}
                <h2 className="text-xl font-semibold text-gray-700">Comment allez-vous payer ?</h2>

                {/* Boutons radio pour les méthodes de paiement */}
                <div className="flex justify-center flex-wrap gap-8 sm:gap-16">
                  {/* Méthode Wave */}
                  <div
                    className={`flex flex-col items-center p-4 border rounded-lg cursor-pointer transition-all duration-300 hover:shadow-lg ${paymentMethod === 'wave-senegal' ? 'ring-blue-500 shadow-lg ring-2' : 'border-gray-300'}`}
                    onClick={() => setPaymentMethod('wave-senegal')}
                  >
                    <img src={waveimg} alt="Wave" className="w-[6.3rem] h-10 mb-2 object-contain" />
                    <label className="cursor-pointer text-center">
                      <input
                        type="radio"
                        value="wave-senegal"
                        checked={paymentMethod === 'wave-senegal'}
                        onChange={() => setPaymentMethod('wave-senegal')}
                        className="hidden"
                      />
                      <span className="text-lg font-semibold">Wave</span>
                    </label>
                  </div>

                  {/* Méthode Orange Money */}
                  <div
                    className={`flex flex-col items-center p-1 border rounded-lg cursor-pointer transition-all duration-300 hover:shadow-lg ${paymentMethod === 'new-orange-money-senegal' ? 'ring-orange-500 shadow-lg ring-2' : 'border-gray-300'}`}
                    onClick={() => setPaymentMethod('new-orange-money-senegal')}
                  >
                    <img src={omimg} alt="Orange Money" className=" h-10 mb-2 object-contain" />
                    <label className="cursor-pointer text-center">
                      <input
                        type="radio"
                        value="new-orange-money-senegal"
                        checked={paymentMethod === 'new-orange-money-senegal'}
                        onChange={() => setPaymentMethod('new-orange-money-senegal')}
                        className="hidden"
                      />
                      <span className="text-lg font-semibold">Orange Money</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="p-4 md:p-5">
                {/* Mode de paiement Orange Money */}
                {paymentMethod === 'new-orange-money-senegal' && (
                  <div className="flex space-x-4 justify-center">
                    <div>
                      <button
                        onClick={() => setPaymentMethodOM('CODE_OTP')}
                        type="button"
                        className={`rounded px-5 py-2.5 overflow-hidden group relative transition-all ease-out duration-300 ${paymentMethodOM === 'CODE_OTP'
                          ? 'bg-green-800 text-white ring-2 ring-orange-400'
                          : 'bg-green-500 text-white hover:bg-gradient-to-r hover:from-green-500 hover:to-green-400 hover:ring-2 hover:ring-offset-2 hover:ring-green-400'
                          }`}
                      >
                        <span className="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
                        <span className="relative text-sm">Payer par code</span>
                      </button>
                    </div>

                    <div>
                      <button
                        onClick={() => setPaymentMethodOM('QR_CODE')}
                        type="button"
                        className={`rounded px-5 py-2.5 overflow-hidden group relative transition-all ease-out duration-300 ${paymentMethodOM === 'QR_CODE'
                          ? 'bg-green-800 text-white ring-2 ring-orange-400'
                          : 'bg-green-500 text-white hover:bg-gradient-to-r hover:from-green-500 hover:to-green-400 hover:ring-2 hover:ring-offset-2 hover:ring-green-400'
                          }`}
                      >
                        <span className="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
                        <span className="relative text-sm">Payer par QR code</span>
                      </button>
                    </div>
                  </div>
                )}
                {paymentMethodOM === 'QR_CODE' && deviceType === 'mobile' && paymentMethod === 'new-orange-money-senegal' && (
                  <div className="flex justify-center space-x-14 pt-4">
                    <div onClick={() => setSelectedPaymentModeOM('om')} className="relative">
                      <div className={`absolute px-1 rounded-full bg-blue-500 text-white transition-transform ${selectedpaymentModeOM === 'om' ? 'scale-100' : 'scale-0'}`}>
                        <FontAwesomeIcon icon={faCheck} />
                      </div>
                      <img src={omimg} alt="Max It" className={`w-[6rem] h-[5.7rem] rounded-full border-2 p-4 ring-1 transition-all ${selectedpaymentModeOM === 'om' ? 'ring-blue-500 border-blue-500' : 'ring-gray-300 border-gray-300'}`} />
                      <div className="p-1"></div>
                      <span className={`font-semibold -ml-2 ${selectedpaymentModeOM === 'om' ? 'text-blue-600' : 'text-gray-800'}`}>Orange Money</span>
                    </div>
                    <div onClick={() => setSelectedPaymentModeOM('maxit')} className="relative">
                      <div className={`absolute px-1 rounded-full bg-blue-500 text-white transition-transform ${selectedpaymentModeOM === 'maxit' ? 'scale-100' : 'scale-0'}`}>
                        <FontAwesomeIcon icon={faCheck} />
                      </div>
                      <img src={maxitlogo} alt="Orange Money" className={`w-[6rem] h-[5.7rem] rounded-full border-2 p-4 ring-1 transition-all ${selectedpaymentModeOM === 'maxit' ? 'ring-blue-500 border-blue-500' : 'ring-gray-300 border-gray-300'}`} />
                      <div className="p-1"></div>
                      <span className={`font-semibold ml-6 pt-6 ${selectedpaymentModeOM === 'maxit' ? 'text-blue-600' : 'text-gray-800'}`}>Max It</span>
                    </div>
                  </div>
                )}
                <section className="bg-white dark:bg-gray-900 py-2 mt-4">
                  <div className="px-4 mx-auto max-w-screen-md space-y-6">
                    <div className="mb-4">
                      <label htmlFor="regions" className="block text-lg font-medium text-gray-700 dark:text-gray-300 mb-2">
                        Choisissez votre région
                      </label>
                      <select
                        id="regions"
                        value={region}
                        onChange={handleRegionChange}
                        className="bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                      >
                        <option value="" disabled>Sélectionnez une région</option>
                        <option value="Dakar">Dakar</option>
                        {deliveryData.map((region) => (
                          <option key={region.id} value={region.name}>
                            {region.name}
                          </option>
                        ))}
                      </select>
                      {region === "Dakar" && (
                        <div className="mt-4 animate__animated animate__fadeInDown">
                          <label htmlFor="districts" className="block text-lg font-medium text-gray-700 dark:text-gray-300 mb-2">
                            Choisissez votre district
                          </label>
                          <select
                            id="districts"
                            required
                            value={selectedDistrict}
                            onChange={handleDistrictChange}
                            className="bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                          >
                            <option value="" disabled>Sélectionnez un district</option>
                            {dakarDistrict.map((district, index) => (
                              <option key={index} value={district.district}>
                                {district.district}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                    </div>

                    {/* Utilisation de grid pour les champs de formulaire afin qu'ils soient en colonne sur mobile et côte à côte sur les grands écrans */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                      <div className="w-full">
                        <label htmlFor="fullName" className="block text-lg font-medium text-gray-700 dark:text-gray-300 mb-2">
                          Nom complet
                        </label>
                        <input
                          type="text"
                          id="fullName"
                          className="block w-full p-3 text-gray-900 bg-gray-50 dark:bg-gray-700 rounded-lg border border-gray-300 dark:border-gray-600 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:placeholder-gray-400 dark:text-white"
                          placeholder="Votre nom complet"
                          required
                          onChange={(e) => setFullName(e.target.value)}
                        />
                      </div>
                      <div className="w-full">
                        <label htmlFor="phoneNumber" className="block text-lg font-medium text-gray-700 dark:text-gray-300 mb-2">
                          Numéro de téléphone
                        </label>
                        <input
                          type="number"
                          id="phoneNumber"
                          className="block w-full p-3 text-gray-900 bg-gray-50 dark:bg-gray-700 rounded-lg border border-gray-300 dark:border-gray-600 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:placeholder-gray-400 dark:text-white"
                          placeholder="Votre numéro de téléphone"
                          required
                          onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                      </div>
                    </div>

                    {paymentMethod === 'new-orange-money-senegal' && paymentMethodOM === 'CODE_OTP' && (
                      <div className="mt-6">
                        <label htmlFor="authorizationCode" className="block text-xs lg:text-lg font-medium text-gray-700 dark:text-gray-300 mb-2">
                          Générer votre code OTP en tapant : <span className="text-red-600 font-semibold">#144#391*VOTRE_CODE_SECRET_ORANGE_MONEY#</span>
                        </label>
                        <input
                          type="text"
                          id="authorizationCode"
                          className="block w-full p-3 text-gray-900 bg-gray-50 dark:bg-gray-700 rounded-lg border border-gray-300 dark:border-gray-600 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:placeholder-gray-400 dark:text-white"
                          placeholder="Exemple: 867890"
                          required
                          onChange={(e) => setauthorizationCode(e.target.value)}
                        />
                      </div>
                    )}
                  </div>
                </section>
              </div>
              {/* Masquer ou afficher les options de mode de paiement selon la région sélectionnée */}
              {region === "Dakar" && (
                <div className="space-y-4 pb-4 ml-4 lg:flex lg:space-x-6 lg:items-center">
                  <label className="lg:mt-4 block text-base font-bold text-gray-900 dark:text-gray-300">
                    Modes de paiement
                  </label>
                  <div className="flex items-center">
                    <input
                      id="paiement_en_ligne"
                      type="radio"
                      value="en_ligne"
                      name="payment_method"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      onChange={() => { setDeliveryPaymentMethod("en_ligne"); ShowAlertModePaiement(); }}
                      checked={deliveryPaymentMethod === "en_ligne"}
                    />
                    <label
                      htmlFor="paiement_en_ligne"
                      className="ml-2 text-base font-bold text-gray-900 dark:text-gray-300"
                    >
                      Payer en ligne
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="paiement_apres_livraison"
                      type="radio"
                      value="apres_livraison"
                      name="payment_method"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      onChange={() => { setDeliveryPaymentMethod("apres_livraison"); ShowAlertModePaiement(); }}
                    />
                    <label
                      htmlFor="paiement_apres_livraison"
                      className="ml-2 text-base font-bold text-gray-900 dark:text-gray-300"
                    >
                      Payer à la livraison
                    </label>
                  </div>
                </div>
              )}
              <div className="grid lg:flex lg:justify-between lg:items-center grid-cols-1 space-y-5 lg:space-y-0 ">
                <div className="pl-3">
                  Total des articles :&nbsp;
                  {Math.floor(paymentTotal)} Fcfa
                </div>
                <div className="pl-3">
                  Livraison:&nbsp;
                  {livraisonPrice && parseFloat(livraisonPrice) > 0 ? (
                    `${Math.floor(parseFloat(livraisonPrice)).toLocaleString('fr-FR')} Fcfa`
                  ) : (
                    <span className="text-red-500">Non disponible</span>
                  )}
                </div>

                <div className="pl-3">
                  <span className="text-red-700 font-bold">
                    Total net à payer:
                  </span>{" "}
                  &nbsp;
                  <span className="animate__animated animate__flash font-bold text-red-600 bg-gray-200 px-1 py-1 rounded-sm">
                    {deliveryPaymentMethod === 'apres_livraison'
                      ? `${Math.floor(parseFloat(districtPrice || 0)).toLocaleString('fr-FR')} Fcfa`
                      : `${Math.floor(parseFloat(calculateTotal())).toLocaleString('fr-FR')} Fcfa`}
                  </span>
                </div>

                <div className="pl-3 flex pr-3">
                  <button
                    onClick={ConfirmerPaiement}
                    type="button"
                    className="bg-green-600 py-2 px-2 rounded-xl border-2 text-white font-semibold text-md"
                  >
                    Terminer
                  </button>
                  <button
                    data-modal-hide="paimentprocessing"
                    type="button"
                    className="bg-red-600 py-2 px-2 rounded-xl border-2 text-white font-semibold text-md"
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentUtils;