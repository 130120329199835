import React, { useEffect, useState } from 'react';
import apiService from '../services/api';
import { CLASSES_PAR_CATEGORIES, ERROR_RECUP, NEW_PATH, OLD_PATH } from '../constant/_const';
import { initFlowbite } from 'flowbite';
import { manageLocalStorage, toastAlert } from '../func/_func';

const PopUpAccessoires = ({ onAccessoryAdded }) => {
  const [accessories, setAccessories] = useState([]);
  const URL_MEDIA = process.env.REACT_APP_API_URL_MEDIA;

  useEffect(() => {
    const fetchAccessoires = async () => {
      try {
        const response = await apiService.get(`${CLASSES_PAR_CATEGORIES}?todo=getaccessoire`);
        setAccessories(response);
      } catch (error) {
        console.error(ERROR_RECUP, error);
      }
    };
    initFlowbite()
    fetchAccessoires();
  }, []);

  const handleAddAccessoryToCart = (accessoryId, accessoryName, image, price) => {
    const cart = manageLocalStorage('get', 'accessory') || [];

    /* Vérifier si l'accessoire est déjà dans le panier */
    const existingAccessory = cart.find(item => item.id === accessoryId);

    if (existingAccessory) {
      /* Si l'accessoire est déjà dans le panier, augmenter la quantité */
      existingAccessory.quantity = (existingAccessory.quantity || 1) + 1;
    } else {
      /* Si l'accessoire n'est pas dans le panier, l'ajouter avec son nom et son image */
      const newAccessory = {
        id: accessoryId,
        name: accessoryName,
        image: image,
        price: price,
        quantity: 1
      };
      cart.push(newAccessory);
    }

    /* Enregistrer le panier mis à jour dans le localStorage */
    manageLocalStorage('add', 'accessory', cart);
    if (onAccessoryAdded) {
      onAccessoryAdded();
    }

    /* Afficher un message pour indiquer que l'accessoire a été ajouté */
    toastAlert("success", `${accessoryName} a été ajouté au panier !`, "top-start", 3000);
  };


  return (
    <>
      <>
        <button
          data-modal-target="popupaccessoires"
          data-modal-toggle="popupaccessoires"
          className="px-4 py-2 rounded-md font-semibold text-white transition-colors duration-300 bg-blue-600 hover:bg-blue-700"
          type="button"
        >
          Ajouter des accessoires
        </button>

        <div
          id="popupaccessoires"
          tabIndex={-1}
          className=" myPaymentUtils fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-full max-h-full"
          style={{ marginLeft: "0rem" }}

        >
          <div className="relative w-full max-w-4xl max-h-full animate__animated animate__fadeInDown">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <form className="pb-4 space-y-2">
                <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                  <h3 className="text-xl font-semibold text-green-800 dark:text-white">
                    Liste des accessoires
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-hide="popupaccessoires"
                  >
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                <div className="p-4 md:p-5 space-y-4">
                  <section className="bg-white dark:bg-gray-900 ">
                    <div className="grid grid-cols-4 grid-rows-1 gap-4 lg:gap-12">
                      {accessories.length > 0 ? (
                        accessories.map((accessory, index) => (
                          <div
                            key={accessory.id}
                            className={`border p-4 rounded-md shadow-md flex flex-col items-center justify-center 
          ${index % 2 === 0 ? 'col-span-2' : 'col-span-2 col-start-3'}`}
                          >
                            {/* Image de l'accessoire */}
                            <div className="w-24 h-24 flex-shrink-0 mb-4">
                              <img
                                src={`${URL_MEDIA}/${accessory.image.replace(OLD_PATH, NEW_PATH)}`}
                                alt={accessory.name}
                                className="w-full h-full object-cover rounded-md"
                              />
                            </div>

                            {/* Infos de l'accessoire */}
                            <div className="text-center">
                              <h3 className="text-lg font-semibold">{accessory.name}</h3>

                            </div>

                            {/* Prix de l'accessoire */}
                            <div className="text-right mt-2">
                              <p className="text-green-600 font-bold">{Math.floor(accessory.price)} FCFA</p>
                            </div>
                            {/* Prix de l'accessoire */}
                            <div className=" mt-2">
                              {/* Bouton "Ajouter au panier" */}
                              <button
                                type="button"
                                className="bg-blue-500 text-sm hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full transition duration-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                onClick={() => handleAddAccessoryToCart(accessory.id, accessory.name, accessory.image, accessory.price)}
                              >
                                Ajouter
                              </button>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p className="text-center col-span-5">Aucun accessoire disponible pour le moment.</p>
                      )}
                    </div>


                  </section>
                </div>

                <div className="grid lg:flex lg:justify-end lg:items-center grid-cols-1 space-y-5 lg:space-y-0 ">
                  <div className="pl-3 flex pr-3">
                    <button
                      data-modal-hide="popupaccessoires"
                      type="button"
                      className="bg-red-600 py-2 px-2 rounded-xl border-2 text-white font-semibold text-md"
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default PopUpAccessoires;