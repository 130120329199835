import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import apiService from "../services/api";
import {
    CLASSES_PAR_CATEGORIES,
    ERROR_RECUP,
    NEW_PATH,
    OLD_PATH,
    SUPPLIES,
} from "../constant/_const";
import Loader from "../common/Loader";
import { manageLocalStorage, showAlert, toastAlert } from "../func/_func";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { CartContext } from "../services/CartContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSchool, faTrash } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import PopUpAccessoires from "./PopUpAccessoires";

const DetailOffres = () => {
    const { id } = useParams();
    const [categoryDetails, setCategoryDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [quantities, setQuantities] = useState({});
    const [filteredSupplies, setFilteredSupplies] = useState([]);
    const [school, setschool] = useState([]);
    const [className, setclassName] = useState([]);
    const [classCategory, setclassCategory] = useState([]);
    const [supplies, setSupplies] = useState([]);
    const [types, setTypes] = useState([]);
    const [imageClass, setImageClass] = useState([]);
    const [selectedType, setSelectedType] = useState("");
    const [selectedSupplyId, setSelectedSupplyId] = useState("");
    const [availableSupplies, setAvailableSupplies] = useState([]);
    const [accessories, setAccessories] = useState([]);
    const [numberOfStudents, setNumberOfStudents] = useState(1);
    const [total, setTotal] = useState(0);
    const { addToCart, cartItems } = useContext(CartContext);
    const URL_MEDIA = process.env.REACT_APP_API_URL_MEDIA;

    const handleAddToCart = () => {
        const accessoriesToAdd = (accessories || []).map((accessory) => ({
            ...accessory,
            quantity: quantities[accessory.id] || 1,
            uniqueKey: `${accessory.id}-${Date.now()}`,
        }));


        const suppliesToAdd = filteredSupplies.map((supply) => ({
            ...supply,
            quantity: quantities[supply.id] || 1,
            uniqueKey: `${supply.id}-${Date.now()}`,
            supply_type: supply.supply_type,
        }));

        const allItemsToAdd = [...accessoriesToAdd, ...suppliesToAdd];
        const updatedCartItems = [...cartItems, ...allItemsToAdd];

        addToCart(updatedCartItems);

        localStorage.setItem("numberOfStudents", numberOfStudents);

        const classSupplies = JSON.parse(localStorage.getItem("classSupplies")) || {};

        /* Identifiants uniques pour la classe et l'école */
        const classKey = `${school}-${className}`;

        /* Initialiser la section pour la classe/école si elle n'existe pas encore */
        if (!Array.isArray(classSupplies[classKey])) {
            classSupplies[classKey] = []; /*  Créer un tableau pour cette classe/école */
        }

        allItemsToAdd.forEach((newItem) => {
            /*  Vérifie si l'article existe déjà dans la classe/école */
            const existingItem = classSupplies[classKey].find(item => item.id === newItem.id);

            if (existingItem) {
                existingItem.quantity += newItem.quantity;
            } else {
                classSupplies[classKey].push({
                    ...newItem,
                    quantity: newItem.quantity,
                    supply_type: newItem.supply_type,
                });
            }
        });

        localStorage.setItem("classSupplies", JSON.stringify(classSupplies));
        const lengthOfClasses = Object.keys(classSupplies).length;
        localStorage.setItem("classSuppliesLength", lengthOfClasses);

        showAlert("Succès", "Articles ajoutés au panier avec succès", 'success');
    };

    useEffect(() => {
        const savedNumberOfStudents = localStorage.getItem("numberOfStudents");
        if (savedNumberOfStudents) {
            setNumberOfStudents(Number(savedNumberOfStudents));
        }
    }, []);

    useEffect(() => {
        const fetchSupplies = async () => {
            try {
                setLoading(true);
                const response = await apiService.get(SUPPLIES);
                setSupplies(response.data);

                const uniqueTypes = [
                    ...new Set(response.data.map((supply) => supply.supply_type)),
                ];
                setTypes(uniqueTypes);
            } catch (error) {
                console.error(ERROR_RECUP, error);
            } finally {
                setLoading(false);
            }
        };
        fetchSupplies();
        Fetchaccessory();
    }, []);

    useEffect(() => {
        const storedData = localStorage.getItem("detailsOffers");
        if (storedData) {
            const { supplies, school, className, classCategory, image } = JSON.parse(storedData);

            if (supplies && typeof supplies === "object") {
                const allSupplies = Object.values(supplies).flat();
                setFilteredSupplies(allSupplies);
                setschool(school);
                setclassName(className);
                setclassCategory(classCategory);
                setImageClass(image);

                const initialQuantities = allSupplies.reduce(
                    (acc, supply) => ({ ...acc, [supply.id]: supply.quantity || 0 }),
                    {}
                );

                setQuantities(initialQuantities);
            }
        }
    }, [categoryDetails]);

    useEffect(() => {
        if (selectedType) {
            const filtered = supplies.filter(
                (supply) => supply.supply_type === selectedType
            );
            setAvailableSupplies(
                filtered.map((supply) => ({ id: supply.id, name: supply.name }))
            );
        } else {
            setAvailableSupplies([]);
        }
    }, [selectedType, supplies]);

    useEffect(() => {

        const supplies = [
            ...filteredSupplies,
            ...(accessories ? accessories.filter(
                (accessory) => !filteredSupplies.some((supply) => supply.id === accessory.id)
            ) : [])
        ];

        const newTotal = supplies.reduce(
            (sum, item) =>
                sum + (parseFloat(item.price) || 0) * (quantities[item.id] || 1),
            0
        );

        setTotal(newTotal * numberOfStudents);
    }, [filteredSupplies, accessories, quantities, numberOfStudents]);

    const incrementStudents = () => setNumberOfStudents((prev) => prev + 1);
    const decrementStudents = () => setNumberOfStudents((prev) => Math.max(1, prev - 1));

    const handleRemoveItem = (id, isAccessory) => {
        const storedData = JSON.parse(localStorage.getItem("detailsOffers")) || {};
        const dataAccessories = manageLocalStorage('get', 'accessory') || {};

        if (isAccessory) {
            setAccessories((prev) => {
                const updatedAccessories = prev.filter(
                    (item) => item.id.toString() !== id.toString()
                );
                dataAccessories.accessories = updatedAccessories;
                manageLocalStorage("update", 'accessory', updatedAccessories);
                return updatedAccessories;
            });
        } else {
            setFilteredSupplies((prev) => {
                const updatedSupplies = prev.filter(
                    (item) => item.id.toString() !== id.toString()
                );

                if (storedData.supplies && typeof storedData.supplies === "object") {
                    Object.keys(storedData.supplies).forEach((type) => {
                        if (!Array.isArray(storedData.supplies[type])) {
                            storedData.supplies[type] = [];
                        }

                        storedData.supplies[type] = storedData.supplies[type].filter(
                            (item) => item.id.toString() !== id.toString()
                        );
                    });
                }

                localStorage.setItem("detailsOffers", JSON.stringify(storedData));

                return updatedSupplies;
            });
        }

        toastAlert("error", "Article supprimé avec succès");
    };

    const incrementQuantity = (id) => {
        setQuantities((prev) => {
            const newQuantities = { ...prev, [id]: (prev[id] || 1) + 1 };

            const updatedSupplies = filteredSupplies.map((supply) =>
                supply.id === id ? { ...supply, quantity: newQuantities[id] } : supply
            );

            updateLocalStorage(updatedSupplies);
            setFilteredSupplies(updatedSupplies);

            return newQuantities;
        });
    };

    const decrementQuantity = (id) => {
        setQuantities((prev) => {
            const newQuantities = { ...prev, [id]: Math.max(1, (prev[id] || 1) - 1) };

            const updatedSupplies = filteredSupplies.map((supply) =>
                supply.id === id ? { ...supply, quantity: newQuantities[id] } : supply
            );

            updateLocalStorage(updatedSupplies);
            setFilteredSupplies(updatedSupplies);

            return newQuantities;
        });
    };

    const groupedFilteredSupplies = filteredSupplies.reduce((acc, supply) => {
        let type = supply.supply_type;
        if (typeof type === "object") {
            type = type.name || "Type inconnu";
        }
        if (!acc[type]) {
            acc[type] = [];
        }
        acc[type].push(supply);
        return acc;
    }, {});

    const handleAddSupply = () => {
        if (!selectedType || !selectedSupplyId) {
            Swal.fire(
                "Erreur",
                "Veuillez sélectionner un type de fourniture et un fourniture.",
                "error"
            );
            return;
        }
        const selectedSupply = supplies.find(
            (supply) => supply.id === parseInt(selectedSupplyId)
        );
        const storedData = localStorage.getItem("detailsOffers");
        let detailsOffers = storedData ? JSON.parse(storedData) : { supplies: {} };
        if (!detailsOffers.supplies[selectedType]) {
            detailsOffers.supplies[selectedType] = [];
        }
        const isSupplyInList = detailsOffers.supplies[selectedType].some(
            (item) => item.id === selectedSupply.id
        );
        if (isSupplyInList) {
            Swal.fire("Erreur", "Ce fourniture est déjà dans la liste.", "error");
            return;
        }
        detailsOffers.supplies[selectedType].push(selectedSupply);
        localStorage.setItem("detailsOffers", JSON.stringify(detailsOffers));
        const updatedFilteredSupplies = Object.values(detailsOffers.supplies).flat();
        setFilteredSupplies(updatedFilteredSupplies);
        setSelectedType("");
        setSelectedSupplyId("");
        showAlert("Succes", "Fourniture ajouté avec succès", 'success');

    };

    const updateLocalStorage = (updatedSupplies) => {
        const storedData = JSON.parse(localStorage.getItem("detailsOffers"));
        if (storedData) {
            storedData.supplies = updatedSupplies;
            localStorage.setItem("detailsOffers", JSON.stringify(storedData));
        }
    };

    const Fetchaccessory = () => {
        const accessoryTocart = manageLocalStorage('get', 'accessory');
        setAccessories(accessoryTocart);
    }

    /* Fonction à passer en prop au composant enfant */
    const handleAccessoryAdded = () => {
        Fetchaccessory();
    };

    if (loading) return <Loader />;

    const isButtonDisabled = "";

    return (
        <>
            <Header />
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 p-4">
                <div className="relative px-4 py-5 lg:px-4">
                    <div className="bg-gray-100 border border-gray-300 rounded-lg p-4 mb-4">
                        <p className="text-gray-700">
                            <span className="font-semibold">École :</span> {school}
                            <span className="mx-2">|</span>
                            <span className="font-semibold">Classe :</span> {className}
                        </p>
                    </div>

                    <div className="flex flex-col gap-4 max-w-full lg:max-w-2xl mx-auto">
                        <div className="relative w-full max-w-xs sm:max-w-sm lg:max-w-md xl:max-w-lg mx-auto">
                            <img
                                src={imageClass}
                                alt="currentClass"
                                className="w-full h-auto rounded-md object-cover"
                            />
                        </div>
                    </div>
                </div>
                <div className="w-full px-4 mt-2">
                    <div className="flex flex-wrap lg:justify-between">
                        <div>
                            <h2 className="font-bold mb-4 bg-gray-100 rounded-md py-1 border-[1.5px] px-2 border-gray-200 text-gray-800 sm:w-[21rem] sm:flex sm:items-center sm:justify-center sm:py-2 sm:px-4 sm:mb-6">
                                <span className="mr-2">
                                    <FontAwesomeIcon icon={faSchool} className="text-blue-500" />
                                </span>
                                Liste des articles fournis par l'école
                            </h2>
                        </div>
                        <div>
                            <PopUpAccessoires onAccessoryAdded={handleAccessoryAdded} />
                        </div>
                    </div>

                    {school === "Autre" && (
                        <div className="w-full px-4 mt-4 bg-white shadow-lg rounded-lg p-4">
                            <div className="grid grid-cols-1 lg:grid-cols-3 items-center space-x-4">
                                {/* Type Selection */}
                                <div className="flex-1 mb-6">
                                    <label
                                        htmlFor="type-select"
                                        className="block text-sm font-semibold text-gray-700"
                                    >
                                        Type de fourniture
                                    </label>
                                    <select
                                        id="type-select"
                                        value={selectedType}
                                        onChange={(e) => setSelectedType(e.target.value)}
                                        className="mt-2 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 ease-in-out"
                                    >
                                        <option value="">Choisir un type de fourniture</option>
                                        {types.map((type, index) => (
                                            <option key={index} value={type}>
                                                {type}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {/* Supplies Selection */}
                                <div className="flex-1 mb-6">
                                    <label
                                        htmlFor="supply-select"
                                        className="block text-sm font-semibold text-gray-700"
                                    >
                                        Fournitures
                                    </label>
                                    <select
                                        id="supply-select"
                                        disabled={!selectedType}
                                        value={selectedSupplyId}
                                        onChange={(e) => setSelectedSupplyId(e.target.value)}
                                        className={`mt-2 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 ease-in-out ${!selectedType
                                            ? "bg-gray-100 cursor-not-allowed"
                                            : "bg-white"
                                            }`}
                                    >
                                        <option value="">Choisir un fourniture</option>
                                        {availableSupplies.map((supply) => (
                                            <option key={supply.id} value={supply.id}>
                                                {supply.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {/* Add Button */}
                                <div className="">
                                    <button
                                        onClick={handleAddSupply}
                                        className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 rounded-md shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
                                    >
                                        Ajouter
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    <br />
                    {Object.keys(groupedFilteredSupplies).map((type, index) => {
                        const filteredSuppliesByType = groupedFilteredSupplies[type].filter(
                            (supply) => supply.provided_by_school
                        );
                        if (filteredSuppliesByType.length === 0) {
                            return null;
                        }
                        return (
                            <><div key={index} className="mb-4">
                                <h3 className="text-xl font-semibold mb-2 underline">{type}</h3>
                                <div className="overflow-x-auto">
                                    <ul className="divide-y divide-gray-200 dark:divide-gray-700 w-full">
                                        {filteredSuppliesByType.map((supply) => (
                                            <li key={supply.id} className="pb-3 sm:pb-4 w-full">
                                                <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-8 rtl:space-x-reverse">
                                                    <p className="text-sm md:w-52 text-gray-500 dark:text-gray-400">
                                                        {supply.name}
                                                    </p>
                                                    <div className="flex items-center space-x-2">
                                                        <span>Quantité</span>
                                                        <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                            <form className="flex items-center">
                                                                <button
                                                                    type="button"
                                                                    onClick={() => decrementQuantity(supply.id)}
                                                                    className="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 inline-flex items-center justify-center border border-gray-300 rounded-md h-5 w-5 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
                                                                >
                                                                    <svg
                                                                        className="w-2.5 h-2.5 text-gray-900 dark:text-white"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 18 2"
                                                                    >
                                                                        <path
                                                                            stroke="currentColor"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            strokeWidth={2}
                                                                            d="M1 1h16" />
                                                                    </svg>
                                                                </button>
                                                                <input
                                                                    type="text"
                                                                    id={`counter-input-${supply.id}`}
                                                                    className="text-gray-900 dark:text-white border-0 bg-transparent text-sm font-normal focus:outline-none focus:ring-0 max-w-[2.5rem] text-center"
                                                                    value={quantities[supply.id] || supply.quantity}
                                                                    readOnly />

                                                                <button
                                                                    type="button"
                                                                    onClick={() => incrementQuantity(supply.id)}
                                                                    className="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 inline-flex items-center justify-center border border-gray-300 rounded-md h-5 w-5 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
                                                                >
                                                                    <svg
                                                                        className="w-2.5 h-2.5 text-gray-900 dark:text-white"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 18 18"
                                                                    >
                                                                        <path
                                                                            stroke="currentColor"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            strokeWidth={2}
                                                                            d="M9 1v16M1 9h16" />
                                                                    </svg>
                                                                </button>
                                                            </form>
                                                        </div>
                                                        <span className="ml-4">
                                                            Prix: {Math.floor(parseFloat(supply.price))}{" "} Fcfa
                                                        </span>

                                                        <button
                                                            title="Supprimer"
                                                            type="button"
                                                            onClick={() => handleRemoveItem(supply.id, false)}
                                                            className="inline-flex bg-gray-300 px-2 py-1.5 rounded-full items-center text-sm font-medium text-red-600 hover:underline dark:text-red-500"
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faTrash}
                                                                className="text-red-500" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            </>
                        );
                    })}
                    <div className="w-full lg:hidden">
                        <div className=" w-full space-y-4">
                            <span className="font-bold"> Liste des accessoires</span>
                            {accessories && accessories.length > 0 ? (
                                accessories.map((accessory) => {
                                    return (
                                        <div
                                            key={accessory.id}
                                            className="rounded-lg border justify-center items-center border-gray-200 bg-white p-4 shadow-sm dark:border-gray-700 dark:bg-gray-800 md:p-6 flex flex-col md:flex-row space-x-2"
                                        >
                                            <div className="shrink-0 md:order-1 mb-4 md:mb-0 lg:ml-6">
                                                <img
                                                    src={
                                                        accessory.image
                                                            ? `${URL_MEDIA}/${accessory.image.replace(OLD_PATH, NEW_PATH)}`
                                                            : "/path/to/default/image.jpg"
                                                    }
                                                    alt={accessory.name}
                                                    className="h-20 w-20 object-cover"
                                                />
                                            </div>
                                            <div className="flex-1 min-w-0 space-y-4 md:space-y-0">
                                                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                                                    {accessory.name}
                                                </h2>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <span>Quantité</span>
                                                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                    <form className="flex items-center">
                                                        <button
                                                            type="button"
                                                            onClick={() => decrementQuantity(accessory.id)}
                                                            className="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 inline-flex items-center justify-center border border-gray-300 rounded-md h-5 w-5 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
                                                        >
                                                            <svg
                                                                className="w-2.5 h-2.5 text-gray-900 dark:text-white"
                                                                aria-hidden="true"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 18 2"
                                                            >
                                                                <path
                                                                    stroke="currentColor"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth={2}
                                                                    d="M1 1h16"
                                                                />
                                                            </svg>
                                                        </button>
                                                        <input
                                                            type="text"
                                                            id={`counter-input-${accessory.id}`}
                                                            className="text-gray-900 dark:text-white border-0 bg-transparent text-sm font-normal focus:outline-none focus:ring-0 max-w-[2.5rem] text-center"
                                                            value={quantities[accessory.id] || 1}
                                                            readOnly
                                                        />
                                                        <button
                                                            type="button"
                                                            onClick={() => incrementQuantity(accessory.id)}
                                                            className="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 inline-flex items-center justify-center border border-gray-300 rounded-md h-5 w-5 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
                                                        >
                                                            <svg
                                                                className="w-2.5 h-2.5 text-gray-900 dark:text-white"
                                                                aria-hidden="true"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 18 18"
                                                            >
                                                                <path
                                                                    stroke="currentColor"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth={2}
                                                                    d="M9 1v16M1 9h16"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </form>
                                                </div>
                                                <span className="ml-4">
                                                    Prix: {parseFloat(accessory.price).toFixed(parseFloat(accessory.price) % 1 === 0 ? 0 : 2)} Fcfa
                                                </span>
                                            </div>
                                            <div className="flex items-center mt-4 md:mt-0 md:ml-4">
                                                <button
                                                    type="button"
                                                    title="Supprimer"
                                                    onClick={() => handleRemoveItem(accessory.id, true)}
                                                    className="inline-flex bg-gray-300 px-2 py-1.5 rounded-full items-center text-sm font-medium text-red-600 hover:underline dark:text-red-500"
                                                >
                                                    <FontAwesomeIcon icon={faTrash} className=" text-red-500" />
                                                </button>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <><p>Aucun accessoire disponible</p><div>
                                    <PopUpAccessoires onAccessoryAdded={handleAccessoryAdded} />
                                </div></>
                            )}

                        </div>
                    </div>
                </div>
            </div>
            <div className="lg:absolute lg:top-[50rem] lg:left-[-7rem] lg:grid grid-cols-5 gap-4 hidden overflow-y-auto lg:h-[33rem] lg:w-[46rem]">
                <div className="col-span-4 pr-4 col-start-2 row-start-2 absolute space-y-3 w-full">
                    <span className="font-bold"> Liste des accessoires</span>
                    {accessories && accessories.length > 0 ? (
                        accessories.map((accessory) => {
                            return (
                                <div
                                    key={accessory.id}
                                    className="rounded-lg border justify-center items-center border-gray-200 bg-white p-4 shadow-sm dark:border-gray-700 dark:bg-gray-800 md:p-6 flex flex-col md:flex-row space-x-2"
                                >
                                    <div className="shrink-0 md:order-1 mb-4 md:mb-0 lg:ml-6">
                                        <img
                                            src={
                                                accessory.image
                                                    ? `${URL_MEDIA}/${accessory.image.replace(OLD_PATH, NEW_PATH)}`
                                                    : "/path/to/default/image.jpg"
                                            }
                                            alt={accessory.name}
                                            className="h-20 w-20 object-cover"
                                        />
                                    </div>
                                    <div className="flex-1 min-w-0 space-y-4 md:space-y-0">
                                        <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                                            {accessory.name}
                                        </h2>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <span>Quantité</span>
                                        <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                            <form className="flex items-center">
                                                <button
                                                    type="button"
                                                    onClick={() => decrementQuantity(accessory.id)}
                                                    className="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 inline-flex items-center justify-center border border-gray-300 rounded-md h-5 w-5 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
                                                >
                                                    <svg
                                                        className="w-2.5 h-2.5 text-gray-900 dark:text-white"
                                                        aria-hidden="true"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 18 2"
                                                    >
                                                        <path
                                                            stroke="currentColor"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={2}
                                                            d="M1 1h16"
                                                        />
                                                    </svg>
                                                </button>
                                                <input
                                                    type="text"
                                                    id={`counter-input-${accessory.id}`}
                                                    className="text-gray-900 dark:text-white border-0 bg-transparent text-sm font-normal focus:outline-none focus:ring-0 max-w-[2.5rem] text-center"
                                                    value={quantities[accessory.id] || 1}
                                                    readOnly
                                                />
                                                <button
                                                    type="button"
                                                    onClick={() => incrementQuantity(accessory.id)}
                                                    className="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 inline-flex items-center justify-center border border-gray-300 rounded-md h-5 w-5 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
                                                >
                                                    <svg
                                                        className="w-2.5 h-2.5 text-gray-900 dark:text-white"
                                                        aria-hidden="true"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 18 18"
                                                    >
                                                        <path
                                                            stroke="currentColor"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={2}
                                                            d="M9 1v16M1 9h16"
                                                        />
                                                    </svg>
                                                </button>
                                            </form>
                                        </div>
                                        <span className="ml-4">
                                            Prix: {parseFloat(accessory.price).toFixed(parseFloat(accessory.price) % 1 === 0 ? 0 : 2)} Fcfa
                                        </span>
                                    </div>
                                    <div className="flex items-center mt-4 md:mt-0 md:ml-4">
                                        <button
                                            type="button"
                                            title="Supprimer"
                                            onClick={() => handleRemoveItem(accessory.id, true)}
                                            className="inline-flex bg-gray-300 px-2 py-1.5 rounded-full items-center text-sm font-medium text-red-600 hover:underline dark:text-red-500"
                                        >
                                            <FontAwesomeIcon icon={faTrash} className=" text-red-500" />
                                        </button>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <><p>Aucun accessoire disponible</p><div>
                            <PopUpAccessoires onAccessoryAdded={handleAccessoryAdded} />
                        </div></>
                    )}

                </div>
            </div>
            <div className="fixed bottom-0 right-0 pb-5 pr-4 bg-white shadow-lg rounded-lg sm:w-full lg:w-full lg:max-w-md border border-gray-300 dark:bg-gray-800 dark:border-gray-600">
                <div className="flex flex-col px-4 py-3 space-y-4">
                    {/* <!-- Nombre d'élèves --> */}
                    <div className="flex items-center justify-between">
                        <span className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                            Nombre d'élèves
                        </span>
                        <div className="flex items-center space-x-2">
                            <button
                                type="button"
                                className="flex-shrink-0 bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600 text-gray-900 dark:text-white border border-gray-300 dark:border-gray-600 rounded-full h-10 w-10 flex items-center justify-center focus:outline-none"
                                onClick={decrementStudents}
                            >
                                <svg
                                    className="w-4 h-4"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 18 2"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M1 1h16"
                                    />
                                </svg>
                            </button>
                            <input
                                type="text"
                                className="text-gray-900 dark:text-gray-100 border border-gray-300 dark:border-gray-600 bg-gray-100 dark:bg-gray-700 rounded-md text-center text-lg font-semibold w-20 focus:outline-none"
                                readOnly
                                value={numberOfStudents}
                            />
                            <button
                                type="button"
                                className="flex-shrink-0 bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600 text-gray-900 dark:text-white border border-gray-300 dark:border-gray-600 rounded-full h-10 w-10 flex items-center justify-center focus:outline-none"
                                onClick={incrementStudents}
                            >
                                <svg
                                    className="w-4 h-4"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 18 18"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M9 1v16M1 9h16"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>

                    {/* <!-- Prix total --> */}
                    <div>
                        <span className="text-lg font-semibold text-gray-900 dark:text-gray-100">
                            Prix total :
                            <span className="text-red-600 font-bold"> {Math.floor(total)} FCFA</span>
                        </span>
                    </div>

                    {/* <!-- Ajouter au panier --> */}
                    <div>
                        <button
                            onClick={handleAddToCart}
                            disabled={isButtonDisabled}
                            className={`px-4 py-2 rounded-md font-semibold text-white transition-colors duration-300
                            ${isButtonDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700 cursor-pointer'}`}
                        >
                            Ajouter au panier
                        </button>

                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default DetailOffres;
