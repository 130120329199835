import React, { useEffect } from "react";
import { notifyUser } from "../../func/_func";

const Completed = () => {
  useEffect(() => {
    notifyUser('Paiement Réussi 🎉', 'Merci pour votre achat dans notre plateforme Digifoire !');
  }, []);
  return (
    <div className="flex h-screen items-center justify-center bg-gradient-to-r from-green-100 via-blue-100 to-green-100">
      <div className="max-w-md w-full bg-white shadow-xl rounded-lg p-8 lg:p-2 text-center">
        <div className="flex justify-center mb-6">
          <svg
            className="w-16 h-16 text-green-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 13l4 4L19 7"
            />
          </svg>
        </div>
        <h2 className="text-3xl font-bold text-gray-800 mb-4">
          Paiement Réussi 🎉
        </h2>
        <p className="text-gray-600 mb-6 text-lg">
          Votre paiement a été complété avec succès. Merci pour votre achat !
        </p>
        <p className="text-lg text-gray-800 font-semibold mb-4">
          Vos articles sont en cours de préparation et seront livrés dans les plus brefs délais.
        </p>
        <p className="text-gray-600 mb-4">
          Pour toute question concernant votre commande, contactez-nous au{" "}
          <strong className="text-green-600">(+221) 78 128 63 98</strong>.
        </p>
        <p className="text-gray-600 mb-6">
          <strong className="text-blue-600">MOUKAT Distribution</strong> et{" "}
          <strong className="text-blue-600">KATi360</strong> vous facilitent
          votre rentrée scolaire avec <strong className="text-blue-600">Digifoire</strong> ! Merci
          pour votre confiance.
        </p>
        <p className="text-gray-600 mb-8">
          Merci encore pour votre achat, et à très bientôt !
        </p>

        <a
          href="/"
          className="inline-block bg-green-500 text-white px-6 py-3 rounded-full font-semibold hover:bg-green-600 transition duration-300 shadow-lg transform hover:scale-105"
        >
          Retour à la page d'accueil
        </a>
      </div>
    </div>
  );
};

export default Completed;