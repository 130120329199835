import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import DetailClasse from "../pages/DetailOffres";
import Site from "../components/Site";
import Panier from "../pages/Panier";
import Details from "../common/Details";
import DetailsClasses from "../pages/DetailsClasses";
import OffresParEcoles from "../pages/OffresParEcoles";
import TestePaiment from "../test/teste";
import Failled from "../modules/Payment/Failled";
import Completed from "../modules/Payment/Completed";
import { useTrackPageView } from "../services/tracking";
import FreeBuying from "../pages/FreeBuying";
import SearchResult from "../common/SearchResult";

const TrackPageView = () => {
  const location = useLocation();
  const { trackPageView } = useTrackPageView();

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location, trackPageView]);

  return null;
};

const AppRoutes = () => {
  return (
    <Router>
      <TrackPageView />
      <Routes>
        <Route path="/teste-payment" element={<TestePaiment />} />
        <Route path="/" element={<Site />} />
        <Route path="/school/:id" element={<OffresParEcoles />} />
        <Route path="/classe/:id" element={<DetailClasse />} />
        <Route path="/details/:id" element={<Details />} />
        <Route path="/classes/details/:id" element={<DetailsClasses />} />
        <Route path="/cart" element={<Panier />} />
        <Route path="/pay-failled" element={<Failled />} />
        <Route path="/pay-completed" element={<Completed />} />
        <Route path="/teste" element={<TestePaiment />} />
        <Route path="/achat-libre" element={<FreeBuying />} />
        <Route path="/searchquery" element={<SearchResult />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
