import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import logo_site from '../assets/images/logo.svg';
import Recherche from '../components/Recherche';
import { manageLocalStorage } from '../func/_func';
import { posthog } from 'posthog-js';
import { useTrackClick } from '../services/tracking';

const Header = () => {
  const classSuppliesLength = manageLocalStorage('get', 'classSuppliesLength') || 0;
  const { trackClick } = useTrackClick();
  return (
    <header className="relative bg-white ">
      <div className="flex items-center justify-between p-4 sm:p-6 lg:p-8">
        <Link to={`/`} className="flex-shrink-0"
          onClick={() => {
            posthog.capture('A cliqué sur Home', {
              user_name: "user_1"
            });
          }}
        >
          <img src={logo_site} alt="logo_site" className="h-8 sm:h-10" />
        </Link>

        <div className="flex items-center space-x-4 mt-3 sm:mt-0">
          <Link
            to={`/cart`}
            onClick={() => {
              trackClick('A cliqué sur le panier', "user_1");
            }}
            className="relative flex-shrink-0 bg-gray-200 px-3 -mt-3 py-2 rounded-full cursor-pointer"
          >
            <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">
              {classSuppliesLength}
            </div>
            <span className="text-green-400 font-bold text-xl">
              <FontAwesomeIcon icon={faShoppingCart} />
            </span>
          </Link>
        </div>
      </div>
      <div className="flex-grow -mt-6">
      <Recherche /> 
      </div>
    </header>
  );
};

export default Header;