import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import apiService from "../services/api";
import {
  CLASSES_PAR_CATEGORIES,
  ERROR_RECUP,
  NEW_PATH,
  OLD_PATH,
} from "../constant/_const";
import Loader from "../common/Loader";
import Header from "../common/Header";
import { Card } from "flowbite-react";
import Footer from "../common/Footer";
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swipper from "../components/_Swipper";
import { manageLocalStorage } from "../func/_func";

const DetailsClasses = () => {
  const { id } = useParams();
  const [categoryDetails, setDetailsClasses] = useState(null);
  const [selectedSchool, setSelectedSchool] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [loading, setLoading] = useState(true);
  const URL_MEDIA = process.env.REACT_APP_API_URL_MEDIA;
  useEffect(() => {
    const fetchDetailsClasses = async () => {
      setLoading(true);
      try {
        const response = await apiService.get(
          `${CLASSES_PAR_CATEGORIES}/${id}`
        );
        setDetailsClasses(response.data);
      } catch (error) {
        console.error(ERROR_RECUP, error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetailsClasses();
  }, [id]);

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (!categoryDetails) {
    return (
      <div>Erreur lors de la récupération des détails de la catégorie.</div>
    );
  }
  const addToDetailsOffers = (categoryDetails, schoolClass) => {
    const data = {
      className: categoryDetails.name,
      classCategory: categoryDetails.class_category,
      school: schoolClass.school,
      supplies: groupSuppliesByType(schoolClass.supplies),
      image: `${URL_MEDIA}/${categoryDetails.image.replace(
        OLD_PATH,
        NEW_PATH
      )}`,
    };
    localStorage.setItem("detailsOffers", JSON.stringify(data));
    manageLocalStorage('remove', 'accessory');

  };
  const schools = categoryDetails.school_classes.map((sc) => sc.school);
  const classes = [categoryDetails.name];

  const groupSuppliesByType = (supplies) => {
    return supplies.reduce((acc, supply) => {
      if (!acc[supply.supply_type]) {
        acc[supply.supply_type] = [];
      }
      acc[supply.supply_type].push(supply);
      return acc;
    }, {});
  };

  return (
    <>
      <Header />
      <div className=" p-4 space-y-6 lg:space-y-0">
        <h2 className="text-2xl font-bold mb-4">Les offres par classe</h2>
        <div className="lg:w-96 lg:px-5 flex flex-col sm:flex-row justify-start pb-5 gap-4">
          <div className="w-full sm:w-1/2">
            <label htmlFor="school_select" className="sr-only">
              Choisir école
            </label>
            {/* <select
              id="school_select"
              className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
              value={selectedSchool}
              onChange={(e) => setSelectedSchool(e.target.value)}
            >
              <option value="" disabled>
                Choisir école
              </option>
              {[...new Set(schools)].map((school, index) => (
                <option key={index} value={school}>
                  {school}
                </option>
              ))}
            </select> */}
            <select
              id="school_select"
              className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
              value={selectedSchool}
              onChange={(e) => setSelectedSchool(e.target.value)}
            >
              <option value="" disabled>
                Choisir école
              </option>
              {[...new Set(schools)]
                .filter((school) => school !== "Autre") /* Exclure "Autre" du tri principal 
                */
                .map((school, index) => (
                  <option key={index} value={school}>
                    {school}
                  </option>
                ))}

              {/* Ajouter l'option "Autre" à la fin */}
              {schools.includes("Autre") && (
                <option value="Autre">Autre</option>
              )}
            </select>
          </div>

        </div>

        <Swipper>
          <div className="flex flex-row space-x-4 scrollbar-hidden overflow-x-auto">
            <div className="flex flex-nowrap justify-start gap-6 sm:gap-8">
              {categoryDetails.school_classes
                .filter(
                  (schoolClass) =>
                    (!selectedClass || categoryDetails.name === selectedClass) &&
                    (!selectedSchool || schoolClass.school === selectedSchool) &&
                    schoolClass.supplies.length > 0
                )
                .map((schoolClass) => {
                  const groupedSupplies = groupSuppliesByType(schoolClass.supplies);

                  return (
                    <div
                      key={`${categoryDetails.id}-${schoolClass.school}`}
                      className=" flex flex-col mb-6 w-full "
                    >
                      <Card
                        imgAlt={categoryDetails.name}
                        imgSrc={`${URL_MEDIA}/${categoryDetails.image.replace(
                          OLD_PATH,
                          NEW_PATH
                        )}`}
                        className="w-80 lg:w-72 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
                      >
                        <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                          {categoryDetails.name}
                        </h5>
                        <h6 className="text-lg font-semibold text-gray-700 dark:text-gray-400 ">
                          {categoryDetails.class_category}
                        </h6>
                        <div className="text-sm text-gray-600 dark:text-gray-400 mb-4">
                          <div key={schoolClass.id} className="w-full space-y-2">
                            <h3 className="text-lg font-semibold mb-2">
                              École : {schoolClass.school}
                            </h3>
                            {Object.keys(groupedSupplies).length > 0 ? (
                              Object.entries(groupedSupplies).map(
                                ([type, supplies]) => (
                                  <div key={type}>
                                    <h4 className="text-md font-semibold ">
                                      {type}
                                    </h4>
                                    <ul className="list-disc mt-2 space-y-2 pr-2">
                                      {supplies.slice(0, 5).map((supply) => (
                                        <li
                                          key={supply.id}
                                          className="flex items-center"
                                        >
                                          <FontAwesomeIcon
                                            icon={faSquareCheck}
                                            className="mr-2 text-blue-500"
                                          />
                                          <p>{supply.name}</p>
                                        </li>
                                      ))}
                                      {supplies.length > 5 && <>. . . .</>}
                                    </ul>
                                  </div>
                                )
                              )
                            ) : (
                              <p className="mt-2 text-center">
                                Aucune fourniture trouvée pour cette classe.
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="px-2 pb-3 w-full text-center">
                          <Link to={`/classe/${categoryDetails.id}`}>
                            <button
                              onClick={() =>
                                addToDetailsOffers(categoryDetails, schoolClass)
                              }
                              className="text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 w-full"
                            >
                              Voir Plus
                            </button>
                          </Link>
                        </div>
                      </Card>
                    </div>
                  );
                })}
            </div>
          </div>
        </Swipper>
      </div>
      <Footer />
    </>
  );
};

export default DetailsClasses;
