import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import { Card } from 'flowbite-react';
import Footer from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import Swipper from '../components/_Swipper';
import { manageLocalStorage } from '../func/_func';
import { NEW_PATH, OLD_PATH } from '../constant/_const';
import Skeleton from 'react-loading-skeleton';
import emptySearch from '../assets/images/empty_searche.jpg';

const SearchResult = () => {
  const [resultat, setResultat] = useState({ data: [] });
  const URL_MEDIA = process.env.REACT_APP_API_URL_MEDIA;
  const [selectedClass, setSelectedClass] = useState('');
  const [displaySkeleton, setDisplaySkeleton] = useState(true);
  const timeout = 1000;

  const fetchResults = () => {
    const fetchedResult = manageLocalStorage('get', 'searchResults') || { data: [] };
    setResultat(fetchedResult);
  };

  if (resultat) {
    setTimeout(() => {
      setDisplaySkeleton(false)
      fetchResults();
    }, timeout);
  }

  const groupSuppliesByType = (supplies) => {
    return supplies.reduce((acc, supply) => {
      if (!acc[supply.supply_type]) {
        acc[supply.supply_type] = [];
      }
      acc[supply.supply_type].push(supply);
      return acc;
    }, {});
  };

  const addToDetailsOffers = (classe, schoolClass) => {
    const data = {
      className: classe.name,
      classCategory: classe.class_category,
      school: schoolClass.school,
      supplies: groupSuppliesByType(schoolClass.supplies),
      image: `${URL_MEDIA}/${classe.image.replace(OLD_PATH, NEW_PATH)}`,
    };
    localStorage.setItem('detailsOffers', JSON.stringify(data));
    manageLocalStorage('remove', 'accessory');
  };

  return (
    <>
      <Header />
      <div className="p-4 space-y-6 lg:space-y-0">
        <h1 className="text-2xl font-bold mb-4 justify-center flex">Resultat de la recherche</h1>
        <div className="lg:w-96 lg:px-5 flex flex-col sm:flex-row justify-start gap-4">
          <div className="w-full sm:w-1/2">
            <label htmlFor="class_select" className="block mb-2 text-sm font-semibold text-gray-700 dark:text-gray-300">
              Choisir classe
            </label>
            <div className="relative">
              <select
                id="class_select"
                className="block w-full py-3 pl-4 pr-10 text-sm text-gray-600 bg-white border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent dark:bg-gray-800 dark:text-gray-300 dark:border-gray-700 dark:focus:ring-blue-500"
                value={selectedClass}
                onChange={(e) => setSelectedClass(e.target.value)}
              >
                <option value="" disabled>
                  Choisir classe
                </option>
                {resultat.data.map((classe) => (
                  <option key={classe.id} value={classe.name}>
                    {`${classe.name} - ${classe.school_classes.length > 0 ? classe.school_classes[0].school : 'École inconnue'}`}
                  </option>
                ))}
              </select>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </span>
            </div>
          </div>
        </div>
        <Swipper>
          {displaySkeleton && (
            <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-3 w-full ">
              {Array.from({ length: 1 }).map((_, index) => (
                <React.Fragment key={index}>
                  <div className="block lg:hidden overflow-hidden">
                    <div className='flex space-x-2'>
                      <Skeleton width={320} height={500} />
                      <Skeleton width={200} height={500} />
                    </div>
                  </div>
                </React.Fragment>
              ))}
              {Array.from({ length: 4 }).map((_, index) => (
                <React.Fragment key={index}>
                  <div className="lg:block hidden overflow-hidden">
                    <div className='flex'>
                      <Skeleton width={index === 3 ? 300 : 400} height={500} />
                    </div>
                  </div>
                </React.Fragment>
              ))}

            </div>
          )}
          {!displaySkeleton && Array.isArray(resultat.data) && resultat.data.length > 0 ? (
            <div className="flex flex-row space-x-4 overflow-x-auto scrollbar-hidden">
              {resultat.data
                .filter((classe) => !selectedClass || classe.name === selectedClass)
                .map((classe) =>
                  classe.school_classes.map((schoolClass, index) => (
                    <div
                      key={`${classe.id}-${schoolClass.school}-${schoolClass.id}-${index}`}
                      className="flex-shrink-0 flex flex-col items-center mb-6 w-80 sm:w-80 md:w-96 lg:w-72 xl:w-80 2xl:w-96"
                    >
                      <Card
                        imgAlt={classe.name}
                        imgSrc={`${URL_MEDIA}/${classe.image.replace(OLD_PATH, NEW_PATH)}`}
                        className="rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
                      >
                        <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                          {classe.name}
                        </h5>
                        <h6 className="text-lg font-semibold text-gray-700 dark:text-gray-400 mb-2">
                          {classe.class_category}
                        </h6>
                        <h3 className="text-lg font-semibold">
                          École : {schoolClass.school}
                        </h3>
                        <div className="text-sm text-gray-600 dark:text-gray-400 mb-4">
                          {schoolClass.supplies.length > 0 ? (
                            <ul className="list-disc mt-2 space-y-2">
                              {schoolClass.supplies.slice(0, 5).map((supply) => (
                                <li key={supply.id} className="flex items-center">
                                  <FontAwesomeIcon icon={faSquareCheck} className="mr-2 text-blue-500" />
                                  <p>{supply.name}</p>
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <p className="mt-2 text-center">Aucune fourniture trouvée pour cette classe.</p>
                          )}
                        </div>
                        <Link to={`/classe/${classe.id}`} className="flex justify-center text-center px-5">
                          <button
                            onClick={() => addToDetailsOffers(classe, schoolClass)}
                            className="text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 w-full"
                          >
                            Voir Plus
                          </button>
                        </Link>
                      </Card>
                    </div>
                  )),
                )}
            </div>
          ) : (
            !displaySkeleton && (
              <div className="flex flex-col items-center justify-center lg:-mt-5 p-4 lg:ml-28">
                <p className="text-center text-gray-500 text-lg md:text-xl font-semibold">
                  Aucun résultat trouvé.
                </p>
                <br />
                <img src={emptySearch} alt="empty search" className="w-2/3 rounded-md md:w-1/2 lg:w-1/3 mb-4" />
              </div>
            )
          )}
        </Swipper>
      </div>
      <Footer />
    </>
  );
};

export default SearchResult;