// Firebase Cloud Messaging Configuration File.
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBMuPEtoRz6tA2LI-s636xHcCGz5jMJ3UY",
  authDomain: "civic-matrix-424115-d1.firebaseapp.com",
  projectId: "civic-matrix-424115-d1",
  storageBucket: "civic-matrix-424115-d1.appspot.com",
  messagingSenderId: "428009174332",
  appId: "1:428009174332:web:4212af4536f81dce779a39",
  measurementId: "G-VYCJ8QCQNV",
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = async () => {
  // The method getToken(): Promise<string> allows FCM to use the VAPID key credential
  // when sending message requests to different push services
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: `BCwiFyFLFuJsPsbad_4lpWI19G9XoaHQkO-kbU_PeLk0TI50p6lQXNOjaXa2cY9Vj3pdK9GkCkGPxTwiVyVIH_8`,
    }); //to authorize send requests to supported web push services
    if (currentToken) {
      console.log("current token for client: ", currentToken);

      if (
        localStorage.getItem("fcmToken") &&
        currentToken !== localStorage.getItem("fcmToken")
      ) {
        localStorage.setItem("fcmToken", currentToken);
      } else if (!localStorage.getItem("fcmToken")) {
        localStorage.setItem("fcmToken", currentToken);
      }
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
  }
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
export const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    });