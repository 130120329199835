import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Annonces from "./Annonces";
import apiService from "../services/api";
import {
  CLASS_CATEGORIES,
  CLASSES,
  CLASSES_PAR_CATEGORIES,
  ERROR_RECUP,
  SCHOOLS,
} from "../constant/_const";
import Classes from "../pages/Classe";
import Header from "../common/Header";
import Loader from "../common/Loader";
import Skeleton from "react-loading-skeleton";
import OffreClasses from "../pages/OffreClasses";
import Footer from "../common/Footer";
import Faq from "../common/Faq";
import School from "../pages/School";
const Site = () => {
  const [classCategories, setClassCategories] = useState([]);
  const [classes, setClasses] = useState([]);
  const [schools, setSchools] = useState([]);
  const [alloffres, setAlloffres] = useState([]);
  /* eslint-disable-next-line no-unused-vars */
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  

  useEffect(() => {
    const fetchClassCategories = async () => {
      setLoading(true);
      try {
        const data = await apiService.get(CLASS_CATEGORIES);
        setClassCategories(data);
      } catch (error) {
        console.error(ERROR_RECUP, error);
      } finally {
        setLoading(false);
      }
    };

    const fetchClasses = async () => {
      try {
        const data = await apiService.get(CLASSES);
        setClasses(data);
      } catch (error) {
        console.error(ERROR_RECUP, error);
      }
    };

    const fetchSchools = async () => {
      try {
        const data = await apiService.get(SCHOOLS);
        setSchools(data);
      } catch (error) {
        console.error(ERROR_RECUP, error);
      }
    };
    const fetchAllOffres = async () => {
      try {
        const data = await apiService.get(CLASSES_PAR_CATEGORIES);
        setAlloffres(data);
      } catch (error) {
        console.error(ERROR_RECUP, error);
      }
    };
    fetchAllOffres();
    fetchSchools();
    fetchClassCategories();
    fetchClasses();
  }, []);

  const getClassByCategory = async (categoryId) => {
    setSelectedCategory(categoryId);
    try {
      const data = await apiService.get(
        `${CLASSES_PAR_CATEGORIES}?category_id=${categoryId}`
      );
      setClasses(data);
      navigate(`/details/${categoryId}`);
    } catch (error) {
      console.error(ERROR_RECUP, error);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Header />

      <div className="flex flex-wrap justify-center pt-6 gap-4 md:gap-6 lg:gap-12">
        {loading
          ? [...Array(5)].map((_, index) => (
            <Skeleton
              key={index}
              width={150}
              height={40}
              borderRadius={20}
              className="text-gray-900 bg-white border border-gray-300 focus:outline-none font-medium rounded-full text-sm px-4 py-2.5 dark:bg-gray-800 dark:text-white dark:border-gray-600"
            />
          ))
          : classCategories
            .filter((classCategory) => classCategory.name !== "Autre")
            .sort((a, b) => {
              const order = [
                "Préscolaire",
                "Elementaire",
                "Collège",
                "Lycée",
              ];
              return order.indexOf(a.name) - order.indexOf(b.name);
            })
            .map((classCategory) => {
              let bgColor;

              switch (classCategory.name) {
                case "Préscolaire":
                  bgColor = "bg-pink-200"; // rose clair
                  break;
                case "Elementaire":
                  bgColor = "bg-yellow-200"; // jaune clair
                  break;
                case "Collège":
                  bgColor = "bg-blue-200"; // bleu clair
                  break;
                case "Lycée":
                  bgColor = "bg-purple-200"; // violet clair
                  break;
                default:
                  bgColor = "bg-white"; // blanc par défaut
              }

              return (
                <button
                  key={classCategory.id}
                  type="button"
                  onClick={() => getClassByCategory(classCategory.id)}
                  className={`${bgColor} active_button text-gray-900 animate__animated animate__flash border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-4 py-2.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700`}
                >
                  {classCategory.name}
                </button>
              );
            })}

        <Link to={"/achat-libre"}>
          <button
            type="button"
            className="active_button text-gray-900 animate__animated animate__flash bg-green-200 border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-4 py-2.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
          >
            Achat libre
          </button>
        </Link>
      </div>
      <School schools={schools} />
      <Classes classes={classes} />
      <Annonces />
      <OffreClasses alloffres={alloffres} />
      <br />
      <Faq />
      <Footer />
    </>
  );
};

export default Site;
