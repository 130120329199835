import React, { useCallback, useEffect, useState } from "react";
import apiService from '../services/api';
import { DAKAR, ERROR_RECUP, NEW_PATH, OLD_PATH, SUPPLIES } from '../constant/_const';
import { filterObjects, manageLocalStorage, notify, toastAlert } from '../func/_func';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faArrowUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { initFlowbite } from 'flowbite';
import { Drawer } from "flowbite-react";
import PaymentUtils from '../modules/Payment/PaymentUtils';
import Loader from "../common/Loader";
import Footer from './../common/Footer';
import { posthog } from 'posthog-js';
import logo_site from '../assets/images/logo.svg';
import { motion, AnimatePresence } from 'framer-motion';
import emptySearch from '../assets/images/empty_searche.jpg';

const FreeBuying = () => {
    const [types, setTypes] = useState([]);
    const [supplies, setSupplies] = useState([]);
    const URL_MEDIA = process.env.REACT_APP_API_URL_MEDIA;
    const [dakarDistrict, setDakarDistrict] = useState([]);
    const [cartLength, setCartLength] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [priceRange, setPriceRange] = useState([0, 50000]);
    const [isOpen, setIsOpen] = useState(false);
    const [numberOfStudents, setNumberOfStudents] = useState(1);
    const [loading, setLoading] = useState(false);
    const [showScrollButton, setShowScrollButton] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [suggestions, setSuggestions] = useState([]);
    const handleClose = () => setIsOpen(false);
    const handleOpen = () => setIsOpen(true);

    useEffect(() => {
        const storedCartLength = manageLocalStorage('get', 'cartLength') || 0;
        setCartLength(storedCartLength);
        const fetchSupplies = async () => {
            try {
                const response = await apiService.get(SUPPLIES);
                setSupplies(response.data);

                /* Créer un ensemble pour obtenir des types uniques */
                const uniqueTypes = [
                    ...new Set(response.data.map((supply) => supply.supply_type)),
                ];
                setTypes(uniqueTypes);
            } catch (error) {
                console.error(ERROR_RECUP, error);
            } finally {
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000);
            }
        };
        const fetchDakarDistrict = async () => {
            try {
                const response = await apiService.get(DAKAR);
                setDakarDistrict(response);
            } catch (error) {
                console.error(ERROR_RECUP, error);
            }
        };

        fetchDakarDistrict();
        initFlowbite();
        fetchSupplies();
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        if (window.scrollY > 4000) {
            setShowScrollButton(true);
        } else {
            setShowScrollButton(false);
        }
    };
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const filterSupplies = () => {
        const normalizedSearchQuery = searchQuery.toLowerCase();

        return supplies.filter((supply) => {
            const normalizedSupplyName = supply.name.toLowerCase();
            const matchesName = normalizedSupplyName.includes(normalizedSearchQuery);
            const matchesType = selectedType ? supply.supply_type === selectedType : true;
            const matchesPrice = supply.price >= priceRange[0] && supply.price <= priceRange[1];

            return matchesName && matchesType && matchesPrice;
        });
    };

    const updateSuggestions = useCallback(() => {
        const filteredSuggestions = supplies
            .filter((supply) => supply.name.toLowerCase().includes(searchQuery.toLowerCase()))
            .slice(0, 5);

        setSuggestions(filteredSuggestions);
    }, [searchQuery, supplies]);

    useEffect(() => {
        updateSuggestions();
    }, [updateSuggestions]);

    const handleReset = () => {
        setSearchQuery('');
    };

    const suppliesBySchool = filterObjects(filterSupplies(), 'provided_by_school', true);
    const accessories = filterObjects(filterSupplies(), 'provided_by_school', false);
    const groupedArticles = types.map(type => ({ type, supplies: filterObjects(suppliesBySchool, 'supply_type', type), }));

    const addToCart = (supply) => {
        const currentCart = manageLocalStorage("get", 'cart') || {};

        const supplyType = supply.supply_type;

        /* Vérifiez si le type de fourniture existe déjà dans le panier */
        if (!currentCart[supplyType]) {
            /* Si non, initialisez-le en tant que tableau */
            currentCart[supplyType] = [];
        }

        /* Vérifiez si l'article existe déjà dans le type de fourniture */
        const existingItemIndex = currentCart[supplyType].findIndex(item => item.id === supply.id);

        if (existingItemIndex !== -1) {
            /* Si l'article existe déjà, augmentez la quantité */
            currentCart[supplyType][existingItemIndex].quantity += 1;
        } else {
            /* Sinon, ajoutez l'article au tableau avec une quantité de 1 */
            const newItem = { ...supply, quantity: 1 };
            currentCart[supplyType].push(newItem);
        }
        updateCartLength(currentCart, setCartLength);
        manageLocalStorage('add', 'cart', currentCart);
        notify(`${supply.name} a été ajouté au panier !`, "success");
    };

    const calculateTotalPrice = (cartSupplies) => {
        let total = 0;

        Object.entries(cartSupplies).forEach(([type, items]) => {
            items.forEach(item => {
                total += item.price * item.quantity;
            });
        });

        return Math.floor(total);
    };

    /* Fonction pour augmenter la quantité d'un article */
    const increaseQuantity = (itemId, type) => {
        const cart = manageLocalStorage("get", 'cart');
        const itemIndex = cart[type].findIndex(item => item.id === itemId);

        if (itemIndex !== -1) {
            cart[type][itemIndex].quantity += 1;
            manageLocalStorage("add", 'cart', cart);
            setSupplies([...supplies]);
        }
    };

    /* Fonction pour diminuer la quantité d'un article */
    const decreaseQuantity = (itemId, type) => {
        const cart = manageLocalStorage("get", 'cart');
        const itemIndex = cart[type].findIndex(item => item.id === itemId);

        if (itemIndex !== -1 && cart[type][itemIndex].quantity > 1) {
            cart[type][itemIndex].quantity -= 1;
            manageLocalStorage("add", 'cart', cart);
            setSupplies([...supplies]);
        } else if (cart[type][itemIndex].quantity === 1) {
            removeItem(itemId, type);
        }
    };

    /* Fonction pour calculer la longueur du panier et mettre à jour le localStorage */
    const updateCartLength = (cart, setCartLength) => {
        const newCartLength = Object.values(cart).reduce((total, items) => {
            return total + items.length;
        }, 0);

        manageLocalStorage("add", 'cartLength', newCartLength);
        setCartLength(newCartLength);
    };

    /* Fonction pour supprimer un article du panier */
    const removeItem = (itemId, type) => {
        const cart = manageLocalStorage("get", 'cart');
        cart[type] = cart[type].filter(item => item.id !== itemId);

        if (cart[type].length === 0) {
            delete cart[type];
        }

        updateCartLength(cart, setCartLength);
        manageLocalStorage("add", 'cart', cart);
        notify("Article supprimé !", 'error');
        setSupplies([...supplies]);
    };

    const increaseStudents = () => {
        setNumberOfStudents(prev => prev + 1);
    };

    const decreaseStudents = () => {
        setNumberOfStudents(prev => (prev > 1 ? prev - 1 : 1));
    };

    const ViderLePanier = () => {
        if (cartLength !== 0) {
            setLoading(true);
            setTimeout(() => { manageLocalStorage('remove', ['cartLength', 'cart', 'totalPrice']); window.location.reload(); }, 1000);
        }
    };

    return (
        <>
            <section className="bg-white py-8 antialiased dark:bg-gray-900 md:py-4">
                <div className="mb-8 px-4">

                    <div className="flex space-x-3 lg:mt-4 flex-wrap lg:flex-nowrap">
                        <Link to={`/`} className="flex-shrink-0 pb-5 ml-3 lg:ml-0 lg:mt-2"
                            onClick={() => {
                                posthog.capture('A cliqué sur Home', {
                                    user_name: "user_1"
                                });
                            }}
                        >
                            <img src={logo_site} alt="logo_site" className="lg:h-8 h-10" />
                        </Link>
                        {/* Search by Name */}
                        <div className="relative w-full ml-2 pb-5">
                            <input
                                type="text"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                placeholder="Recherchez par nom"
                                className="w-full p-3 border rounded-md shadow-md focus:ring focus:ring-green-500 focus:outline-none"
                            />
                            {searchQuery && (
                                <button
                                    onClick={handleReset}
                                    className="absolute right-3 top-3 text-gray-500 hover:text-gray-800 focus:outline-none"
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            )}

                            {/* Liste des suggestions */}
                            {searchQuery && suggestions.length > 0 && (
                                <ul className="absolute w-full bg-white border border-gray-300 rounded-md shadow-lg z-10">
                                    {suggestions.map((supply) => (
                                        <li
                                            key={supply.id}
                                            className="p-2 cursor-pointer hover:bg-gray-100"
                                            onClick={() => {
                                                setSearchQuery(supply.name);
                                                setSuggestions([]);
                                            }}
                                        >
                                            {supply.name}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>

                        {/* Filter by Type */}
                        <div className="w-full ">
                            <select
                                value={selectedType}
                                onChange={(e) => setSelectedType(e.target.value)}
                                className="w-full p-3 border rounded-md shadow-md bg-white dark:bg-gray-800 focus:ring focus:ring-green-500 focus:outline-none"
                            >
                                <option value="">Tous les Types</option>
                                {types.map(type => (
                                    <option key={type} value={type}>{type}</option>
                                ))}
                            </select>
                        </div>

                        {/* Price Range Filter */}
                        <div className="flex flex-col w-full lg:w-auto lg:-mt-[1rem] mt-3 space-y-2 lg:space-y-0">
                            <label htmlFor="minPrice" className="text-sm font-medium text-gray-600 dark:text-gray-300">Min Prix</label>
                            <input
                                type="number"
                                value={priceRange[0]}
                                onChange={(e) => setPriceRange([+e.target.value, priceRange[1]])}
                                className="w-full lg:w-24 p-2 border rounded-md shadow-md focus:ring focus:ring-green-500 focus:outline-none"
                            />
                        </div>
                        <div className="flex flex-col w-full lg:w-auto lg:-mt-[1rem] mt-3 space-y-2 lg:space-y-0">
                            <label htmlFor="maxPrice" className="text-sm font-medium text-gray-600 dark:text-gray-300">Max Prix</label>
                            <input
                                type="number"
                                value={priceRange[1]}
                                onChange={(e) => setPriceRange([priceRange[0], +e.target.value])}
                                className="w-full lg:w-24 p-2 border rounded-md shadow-md focus:ring focus:ring-green-500 focus:outline-none"
                            />
                        </div>

                        {/* Cart Icon */}
                        <div className="relative mt-5 lg:-mt-6 flex items-center justify-center">

                            <Link
                                className="flex-shrink-0 bg-gray-200 px-3 py-2 rounded-full shadow-md cursor-pointer hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 transition-all"
                                onClick={() => handleOpen(true)}
                            >
                                <div className="absolute lg:mt-5 inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">
                                    {cartLength}
                                </div>
                                <span className="text-green-400 font-bold text-xl">
                                    <FontAwesomeIcon icon={faShoppingCart} />
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
                {isLoading ? (
                    <div className=" h-auto w-full space-x-3" style={{ overflow: 'hidden' }}>
                        {/* Simulation du bouton unique */}
                        <div className="flex mb-4 ml-2 ">
                            <Skeleton width={150} height={40} />
                        </div>

                        {/* Simulation des cartes de fournitures */}
                        <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-6 w-full" style={{ overflow: 'hidden' }}>
                            {Array.from({ length: 12 }).map((_, index) => (
                                <React.Fragment key={index}>
                                    <div className="lg:block hidden">
                                        <Skeleton width={280} height={300} />
                                    </div>
                                    <div className="block lg:hidden">
                                        <Skeleton width={360} height={300} />
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>

                ) : (
                    <div>
                        {/* Section pour Articles */}
                        <div>
                            {groupedArticles.some(group => group.supplies.length > 0) || accessories.length > 0 ? (
                                <>
                                    {/* Articles */}
                                    {groupedArticles.map((group) => (
                                        group.supplies.length > 0 && (
                                            <div key={group.type} className="mb-8">
                                                <button type="button" className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 ml-4">
                                                    {group.type}
                                                </button>

                                                <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-6 px-4">
                                                    <AnimatePresence>
                                                        {group.supplies.map((supply) => (
                                                            <motion.div
                                                                key={supply.id}
                                                                initial={{ opacity: 0, scale: 0.95 }}
                                                                animate={{ opacity: 1, scale: 1 }}
                                                                exit={{ opacity: 0, scale: 0.95 }}
                                                                transition={{ type: "spring", stiffness: 500, damping: 20 }}
                                                                className="relative rounded-lg border border-gray-200 bg-white p-4 shadow-sm transition-transform transform hover:scale-105 hover:shadow-lg dark:border-gray-700 dark:bg-gray-800 md:p-6"
                                                            >
                                                                <img
                                                                    src={`${URL_MEDIA}/${supply.image.replace(OLD_PATH, NEW_PATH)}`}
                                                                    alt={supply.name}
                                                                    className="h-20 w-20 rounded-md mx-auto"
                                                                />
                                                                <div className="mt-4 text-center">
                                                                    <p className="text-base font-medium text-gray-900 dark:text-white">{supply.name}</p>
                                                                    <p className="text-sm text-gray-500 dark:text-gray-400">Prix : {Math.floor(supply.price)} Fcfa</p>
                                                                    <button
                                                                        onClick={() => { addToCart(supply); }}
                                                                        className="mt-4 inline-flex items-center justify-center px-4 py-2 bg-blue-600 text-white font-medium text-sm rounded-md hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 transition-all cursor-pointer"
                                                                    >
                                                                        Ajouter au panier
                                                                    </button>
                                                                </div>
                                                            </motion.div>
                                                        ))}
                                                    </AnimatePresence>
                                                </div>
                                            </div>
                                        )
                                    ))}

                                    {/* Accessoires */}
                                    {accessories.length > 0 && (
                                        <div className="mt-8">
                                            <button type="button" className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 ml-4">
                                                Accessoires
                                            </button>
                                            <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-6 px-4">
                                                <AnimatePresence>
                                                    {accessories.map((supply) => (
                                                        <motion.div
                                                            key={supply.id}
                                                            initial={{ opacity: 0, scale: 0.95 }}
                                                            animate={{ opacity: 1, scale: 1 }}
                                                            exit={{ opacity: 0, scale: 0.95 }}
                                                            transition={{ type: "spring", stiffness: 500, damping: 20 }}
                                                            className="relative rounded-lg border border-gray-200 bg-white p-4 shadow-sm transition-transform transform hover:scale-105 hover:shadow-lg dark:border-gray-700 dark:bg-gray-800 md:p-6"
                                                        >
                                                            <img
                                                                src={`${URL_MEDIA}/${supply.image.replace(OLD_PATH, NEW_PATH)}`}
                                                                alt={supply.name}
                                                                className="h-20 w-20 rounded-md mx-auto"
                                                            />
                                                            <div className="mt-4 text-center">
                                                                <p className="text-base font-medium text-gray-900 dark:text-white">{supply.name}</p>
                                                                <p className="text-sm text-gray-500 dark:text-gray-400">Prix : {Math.floor(supply.price)} Fcfa</p>
                                                                <button
                                                                    onClick={() => { addToCart(supply); }}
                                                                    className="mt-4 inline-flex items-center justify-center px-4 py-2 bg-blue-600 text-white font-medium text-sm rounded-md hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 transition-all cursor-pointer"
                                                                >
                                                                    Ajouter au panier
                                                                </button>
                                                            </div>
                                                        </motion.div>
                                                    ))}
                                                </AnimatePresence>
                                            </div>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div className="flex flex-col items-center justify-center -mt-8 lg:-mt-12 p-4">
                                    <p className="text-center text-gray-500 text-lg md:text-xl font-semibold">
                                        Aucun résultat trouvé.
                                    </p>
                                    <br />
                                    <img src={emptySearch} alt="empty search" className="w-2/3 rounded-md md:w-1/2 lg:w-1/3 mb-4" />
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {/* Bouton pour défiler vers le haut */}
                {showScrollButton && (
                    <button
                        onClick={scrollToTop}
                        className="fixed bottom-16 right-5 bg-green-600 text-white rounded-full p-3 shadow-md hover:bg-green-700 transition"
                    >
                        <FontAwesomeIcon icon={faArrowUp} className="text-xl" />
                    </button>
                )}
            </section>

            <Drawer open={isOpen} onClose={handleClose} position="top">
                <Drawer.Header title="Panier" />
                <Drawer.Items>
                    {manageLocalStorage("get", "cart") ? (
                        (() => {
                            const cartSupplies = manageLocalStorage("get", "cart");
                            const totalPrice = calculateTotalPrice(cartSupplies) * numberOfStudents;
                            manageLocalStorage("add", "totalPrice", totalPrice)
                            /* Trier les fournitures par type */
                            const sortedCartSupplies = Object.entries(cartSupplies).sort(([typeA], [typeB]) => {
                                return typeA.localeCompare(typeB);
                            });
                            const order = [{
                                schoolName: "Autre",
                                className: "Classe Other",
                                total: totalPrice,
                                number_of_students: numberOfStudents,
                            }];
                            const orderItem = [{
                                supplies: []
                            }];

                            /* Parcourir chaque catégorie dans cartSupplies */
                            for (const category in cartSupplies) {
                                const items = cartSupplies[category];

                                /*  Parcourir chaque élément dans la catégorie */
                                items.forEach(item => {
                                    orderItem[0].supplies.push({
                                        id: item.id,
                                        name: item.name,
                                        price: item.price,
                                        quantity: item.quantity
                                    });
                                });
                            }
                            return (
                                <div className="dark:bg-gray-800 rounded-lg">
                                    <div className='flex flex-col md:flex-row justify-between items-center space-y-2 px-2 w-full pb-4'>
                                        <div className='flex items-center mb-4 md:mb-0'>
                                            <label htmlFor="numberOfStudents" className="mr-2 text-gray-700 dark:text-gray-300">Nombres d'élèves :</label>
                                            <button
                                                onClick={decreaseStudents}
                                                className="ml-2 px-2 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 transition duration-200"
                                                aria-label="Diminuer le nombre d'élèves"
                                            >
                                                -
                                            </button>
                                            <span id="numberOfStudents" className="mx-2 font-semibold">{numberOfStudents}</span>
                                            <button
                                                onClick={increaseStudents}
                                                className="ml-2 px-2 py-1 bg-green-500 text-white rounded-md hover:bg-green-600 transition duration-200"
                                                aria-label="Augmenter le nombre d'élèves"
                                            >
                                                +
                                            </button>
                                        </div>
                                        <span className="font-bold text-lg text-gray-900 dark:text-white">
                                            Total : {totalPrice} Fcfa
                                        </span>
                                        <div className="flex space-x-2">
                                            <PaymentUtils totalPrice={totalPrice} dakarDistrict={dakarDistrict} order={order} orderItem={orderItem} />
                                            {loading ? (
                                                <Loader />
                                            ) : (
                                                <button
                                                    id="viderlepanier"
                                                    onClick={ViderLePanier}
                                                    disabled={cartLength === 0}
                                                    className={`z-[1] py-2 px-4 rounded ${cartLength === 0 ? 'bg-gray-400 cursor-not-allowed' : 'bg-red-500 text-white cursor-pointer'}`}>
                                                    {cartLength === 0 ? 'Panier Vide' : 'Vider le panier'}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    {/* Scrollable Container */}
                                    <div
                                        className="max-h-[75vh] overflow-y-auto lg:pr-1 "
                                        style={{
                                            scrollbarWidth: 'none',
                                            msOverflowStyle: 'none',
                                        }}
                                    >
                                        {/* Boucle sur chaque type de fourniture */}
                                        {sortedCartSupplies.map(([type, items]) => (
                                            <div key={type} className="mb-6">
                                                <h5 className="text-xl font-bold  text-gray-900 dark:text-white mb-4">{type}</h5>

                                                <div className="grid lg:pb-5 lg:pl-2 lg:pt-2 pb-12 gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                                                    {items.map((item) => (
                                                        <div
                                                            key={item.id}
                                                            className="bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-xl shadow-xl transform hover:scale-105 transition-transform duration-300 ease-in-out p-6 flex flex-col items-center justify-between space-y-4">

                                                            {/* Image avec effet hover */}
                                                            <div className="relative w-full flex justify-center">
                                                                <img
                                                                    src={`${URL_MEDIA}/${item.image.replace(OLD_PATH, NEW_PATH)}`}
                                                                    alt={item.name}
                                                                    className="h-24 w-24 object-contain rounded-lg shadow-md transition-transform duration-300 ease-in-out hover:scale-110"
                                                                />
                                                            </div>

                                                            {/* Nom du produit */}
                                                            <h5 className="text-lg font-semibold text-gray-900 dark:text-white text-center mb-2">{item.name}</h5>

                                                            {/* Prix du produit */}
                                                            <p className="text-md text-gray-700 dark:text-gray-300">
                                                                Prix : <span className="font-bold text-gray-900 dark:text-white">{Math.floor(item.price)} Fcfa</span>
                                                            </p>

                                                            {/* Sélection de quantité avec des boutons stylisés */}
                                                            <div className="flex items-center space-x-4 mb-4">
                                                                <button
                                                                    onClick={() => decreaseQuantity(item.id, type)}
                                                                    className="p-2 bg-red-500 text-white rounded-full shadow-md hover:bg-red-600 transition-colors duration-200 ease-in-out">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-5 h-5">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4" />
                                                                    </svg>
                                                                </button>

                                                                <p className="text-lg font-semibold text-gray-900 dark:text-white">{item.quantity}</p>

                                                                <button
                                                                    onClick={() => increaseQuantity(item.id, type)}
                                                                    className="p-2 bg-green-500 text-white rounded-full shadow-md hover:bg-green-600 transition-colors duration-200 ease-in-out">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-5 h-5">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
                                                                    </svg>
                                                                </button>
                                                            </div>

                                                            {/* Bouton pour supprimer l'article */}
                                                            <button
                                                                onClick={() => removeItem(item.id, type)}
                                                                className="px-4 py-2 bg-red-600 text-white rounded-lg shadow-lg hover:bg-red-700 transition-transform duration-200 ease-in-out hover:scale-105">
                                                                Supprimer
                                                            </button>

                                                        </div>

                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            );
                        })()
                    ) : (
                        <p className="text-gray-600 dark:text-gray-300">Aucune fourniture dans le panier.</p>
                    )}
                </Drawer.Items>
            </Drawer>
            <Footer />
        </>
    );
};

export default FreeBuying;